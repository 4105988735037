import React from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, TextField } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";

import { toast } from "components/Toast";
import { FileInput } from "components/FileInput";
import { Form } from "components/Form";
import { routes } from "consts/routes";
import { useTitle } from "hooks/useAppTheme";
import { SelectWithController } from "components/Select";
import { products } from "api/products";
import { upgradeGroups } from "api/upgradeGroups";
import { parseApiError } from "utils/apiResponses";
import { getErrorMessage } from "pages/RequestUpgrade/utils";

import styles from "./RequestUpgrade.module.scss";

export const RequestUpgrade = () => {
  useTitle("Request Devices Upgrade");

  type RequestUpgradeFormType = {
    name: string;
    product_version_id: string;
    file: File;
  };

  const createDeviceGroupForm = useForm<RequestUpgradeFormType>({
    defaultValues: {
      name: "",
      product_version_id: "",
    },
    resolver: yupResolver(
      object().shape({
        name: string().trim().max(50).required().label("Group name"),
        product_version_id: string().required().label("Product version"),
      })
    ),
  });

  const {
    control,
    formState: { errors },
    register,
  } = createDeviceGroupForm;

  const navigate = useNavigate();

  // technically, on prod there'll be only one product but because of the current API structure and lack of time
  // we have to fetch it like that to get the product versions below. Not ideal and an unnecessary API call
  // but simplifying it would require too many API changes. Might want to discuss it later.
  const { data: productsData, isLoading: isFetchingProducts } =
    products.useGetProducts();

  let productId = React.useMemo(
    () => productsData?.[0]?.id || "",
    [productsData]
  );

  // we have to assume there's only one product and take the first one from the array
  const { data: productVersions, isLoading: isFetchingProductVersions } =
    products.useGetProductVersions(productId);

  const { mutate: createDeviceGroup, isLoading } =
    upgradeGroups.useCreateUpgradeGroup();

  const handleSubmit = ({
    name,
    product_version_id,
    file,
  }: RequestUpgradeFormType) => {
    const formData = new FormData();

    formData.append("name", name);
    formData.append("product_id", productId);
    formData.append("product_version_id", product_version_id);
    formData.append("file", file);

    createDeviceGroup(formData, {
      onSuccess: () => {
        navigate(routes.UPGRADE.ROOT);
      },
      onError: (error) => toast("error", getErrorMessage(parseApiError(error))),
    });
  };

  return (
    <div className={styles.page}>
      <Form
        form={createDeviceGroupForm}
        onSubmit={handleSubmit}
        className={styles.form}
      >
        <TextField
          {...register("name")}
          className={styles.input}
          variant="filled"
          label="Group name"
          disabled={isLoading}
          fullWidth
          error={!!errors.name}
          helperText={errors.name?.message}
        />
        <SelectWithController
          labelText="Product version"
          name="product_version_id"
          disabled={
            isFetchingProducts || isFetchingProductVersions || isLoading
          }
          control={control}
          errorMessage={errors.product_version_id?.message}
          items={productVersions?.map(({ id = "", version_name }) => {
            return { label: version_name, value: id };
          })}
        />
        <FileInput
          name="file"
          errorMessage={errors.file?.message}
          control={control}
          label="Upload devices file"
          disabled={isLoading}
        />
        <Button type="submit" variant="contained" disabled={isLoading}>
          Request Group Upgrade
        </Button>
        <p className={styles.hint}>
          Hint: the uploaded file should be a .csv format file containing a list
          of IDs of the devices you'd like to upgrade within the created group.
        </p>
      </Form>
    </div>
  );
};
