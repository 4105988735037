import React from "react";

import { LogsTable } from "pages/DeviceLogs/LogsTable";
import { useTitle } from "hooks/useAppTheme";

const MOCKED_ITEMS = [
  {
    source: "device",
    content: {
      _id: "61ba3a779301f0b9527499ef",
      index: 0,
      guid: "8324e1c2-4ab5-4ecb-9a81-f68e481d7c5e",
    },
    time: "2021-01-05 09:51:27",
    header: {
      _id: "61ba3a779301f0b9527499ef",
      index: 0,
      guid: "8324e1c2-4ab5-4ecb-9a81-f68e481d7c5e",
    },
  },
  {
    source: "cloud",
    content: {
      _id: "61ba3a779301f0b9527499ef",
      index: 0,
      guid: "8324e1c2-4ab5-4ecb-9a81-f68e481d7c5e",
    },
    time: "2021-01-05 09:51:27",
    header: {
      _id: "61ba3a779301f0b9527499ef",
      index: 0,
      guid: "8324e1c2-4ab5-4ecb-9a81-f68e481d7c5e",
    },
  },
  {
    source: "device",
    content: {
      _id: "61ba3a779301f0b9527499ef",
      index: 0,
      guid: "8324e1c2-4ab5-4ecb-9a81-f68e481d7c5e",
    },
    time: "2021-01-05 09:51:27",
    header: {
      _id: "61ba3a779301f0b9527499ef",
      index: 0,
      guid: "8324e1c2-4ab5-4ecb-9a81-f68e481d7c5e",
    },
  },
  {
    source: "device",
    content: {
      _id: "61ba3a779301f0b9527499ef",
      index: 0,
      guid: "8324e1c2-4ab5-4ecb-9a81-f68e481d7c5e",
    },
    time: "2021-01-05 09:51:27",
    header: {
      _id: "61ba3a779301f0b9527499ef",
      index: 0,
      guid: "8324e1c2-4ab5-4ecb-9a81-f68e481d7c5e",
    },
  },
];

// TODO get back to it when we've got actual devices to work with
export const DeviceLogs = () => {
  useTitle("Logs");

  return <div>{/* <LogsTable logs={MOCKED_ITEMS} /> */}</div>;
};
