import React from "react";
import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import cx from "clsx";

import { routes } from "consts/routes";
import { resolveUrl } from "utils/urls";

import styles from "./ApplicationsTable.module.scss";
import { ApplicationOutputSchema } from "api/generated";

const HEADERS = ["Name", "Options"];

type ApplicationsTableProps = {
  data: ApplicationOutputSchema[];
};

export const ApplicationsTable = ({ data }: ApplicationsTableProps) => {
  return (
    <>
      <Table>
        <TableHead>
          <TableRow classes={{ root: cx(styles.head, styles.row) }}>
            {HEADERS.map((label, i) => (
              <TableCell className={styles.header} key={i}>
                {label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(({ id, name }, i) => (
            <TableRow classes={{ root: styles.row }} key={i}>
              <TableCell className={styles.cell}>{name}</TableCell>
              <TableCell className={styles.cell}>
                <Link
                  component={RouterLink}
                  to={resolveUrl(routes.APPLICATIONS.EDIT.ROOT, { id })}
                  classes={{ root: styles.link }}
                  underline="none"
                >
                  Edit
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};
