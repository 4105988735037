import { Navigate } from "react-router-dom";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { routes } from "consts/routes";

type RouteTypeProps = {
  children: JSX.Element;
  redirectTo?: string;
};

export const RequireAuth = ({
  children,
  redirectTo = routes.LOGIN,
}: RouteTypeProps) => (
  <>
    <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
    <UnauthenticatedTemplate>
      <Navigate to={redirectTo} />
    </UnauthenticatedTemplate>
  </>
);

export const PublicOnly = ({
  children,
  redirectTo = routes.PRODUCTS.ROOT,
}: RouteTypeProps) => (
  <>
    <AuthenticatedTemplate>
      <Navigate to={redirectTo} />
    </AuthenticatedTemplate>
    <UnauthenticatedTemplate>{children}</UnauthenticatedTemplate>
  </>
);
