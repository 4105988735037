// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/login-background.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Login_page__1ybUO {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: 100% 0;\n  background-size: cover;\n  background-repeat: no-repeat;\n  min-height: 100vh;\n  background-color: #f4f4f4;\n  padding-top: 192px;\n}\n\n.Login_buttonsContainer__DKg-n {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin: 0 auto;\n  max-width: 428px;\n  width: 100%;\n  padding: 24px;\n  background: white;\n  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 8px 0px;\n}\n\n.Login_button__urnCv {\n  width: 128px;\n}", "",{"version":3,"sources":["webpack://./src/pages/Login/Login.module.scss","webpack://./src/styles/_vars.scss"],"names":[],"mappings":"AAEA;EACE,yDAAA;EACA,2BAAA;EACA,sBAAA;EACA,4BAAA;EACA,iBAAA;EAEA,yBAAA;EACA,kBAAA;AAFF;;AAKA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,cAAA;EACA,gBAAA;EACA,WAAA;EACA,aAAA;EACA,iBAAA;EACA,8CCtBY;ADoBd;;AAKA;EACE,YAAA;AAFF","sourcesContent":["@use \"src/styles/vars\";\n\n.page {\n  background-image: url(\"../../assets/images/login-background.png\");\n  background-position: 100% 0;\n  background-size: cover;\n  background-repeat: no-repeat;\n  min-height: 100vh;\n  // TODO move this to colors styling file when its built\n  background-color: #f4f4f4;\n  padding-top: 192px;\n}\n\n.buttonsContainer {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin: 0 auto;\n  max-width: 428px;\n  width: 100%;\n  padding: 24px;\n  background: white;\n  box-shadow: vars.$base-shadow;\n}\n\n.button {\n  width: 128px;\n}\n","$base-shadow: rgba(0, 0, 0, 0.1) 0px 2px 8px 0px;\n\n$topbar-height: 48px;\n$side-menu-width: 256px;\n\n$document-width: 540px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": "Login_page__1ybUO",
	"buttonsContainer": "Login_buttonsContainer__DKg-n",
	"button": "Login_button__urnCv"
};
export default ___CSS_LOADER_EXPORT___;
