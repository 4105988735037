import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import styles from "./ConfirmationDialog.module.scss";

type ConfirmationDialogProps = {
  title: string;
  message: string;
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

export const ConfirmationDialog = ({
  title,
  message,
  isOpen,
  onConfirm,
  onCancel,
}: ConfirmationDialogProps) => {
  return (
    <Dialog
      classes={{ paper: styles.container }}
      open={isOpen}
      onClose={onCancel}
    >
      <DialogTitle classes={{ root: styles.title }}>{title}</DialogTitle>
      <DialogContent classes={{ root: styles.content }}>
        <DialogContentText classes={{ root: styles.message }}>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions classes={{ root: styles.actions }}>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onConfirm} variant="contained" autoFocus>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};
