// File does not contain correct UUIDs.
export const getErrorMessage = (message: string) => {
  switch (message) {
    case "File does not contain correct UUIDs.":
      return "Invalid file type or structure of the file. Could not read the correct IDs of the devices.";
    case "field required":
      return "Some fields are missing.";
    default:
      return message;
  }
};
