import React from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import cx from "clsx";

import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

import { auth } from "api/auth";
import { NAV_ITEMS } from "consts/navItems";
import { ReactComponent as LogoutIcon } from "assets/icons/icon-logout.svg";

import styles from "./SideMenu.module.scss";

export const SideMenu = () => {
  const location = useLocation();
  const { useLogout: logout } = auth;

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      classes={{ root: styles.root, paper: styles.paper }}
    >
      <List classes={{ root: styles.list }}>
        {NAV_ITEMS.map(({ text, icon, to, subsectionName }, i) => {
          // TODO -- this is a quick workaround to avoid clashing with RR v6 logic, make it better later
          const pathStart = location.pathname.substring(1);

          return (
            <Link
              to={to}
              className={cx(
                { [styles.activeLink]: pathStart.startsWith(subsectionName) },
                styles.link
              )}
              key={i}
            >
              <ListItem
                button
                key={text}
                disablePadding
                className={styles.listItem}
              >
                {icon}
                <p>{text}</p>
              </ListItem>
            </Link>
          );
        })}
        <ListItem button disablePadding onClick={() => logout()}>
          <LogoutIcon />
          <p>Logout</p>
        </ListItem>
      </List>
    </Drawer>
  );
};
