// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CreateClientVersion_form__KTgr7 {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  max-width: 420px;\n  width: 100%;\n}\n\n.CreateClientVersion_input__L\\+iw3 {\n  margin-bottom: 24px;\n}\n\n.CreateClientVersion_divider__Y3rRb {\n  width: 100%;\n  margin-bottom: 32px;\n}", "",{"version":3,"sources":["webpack://./src/pages/CreateClientVersion/CreateClientVersion.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,gBAAA;EACA,WAAA;AADF;;AAIA;EACE,mBAAA;AADF;;AAIA;EACE,WAAA;EACA,mBAAA;AADF","sourcesContent":["@use \"src/styles/colors\";\n\n.form {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  max-width: 420px;\n  width: 100%;\n}\n\n.input {\n  margin-bottom: 24px;\n}\n\n.divider {\n  width: 100%;\n  margin-bottom: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "CreateClientVersion_form__KTgr7",
	"input": "CreateClientVersion_input__L+iw3",
	"divider": "CreateClientVersion_divider__Y3rRb"
};
export default ___CSS_LOADER_EXPORT___;
