import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import { Form } from "components/Form";
import { applications } from "api/applications";

import styles from "./EditApplicationForm.module.scss";

type EditApplicationFormProps = {
  name: string;
  id: string;
};

export const EditApplicationForm = ({ name, id }: EditApplicationFormProps) => {
  type EditApplicationFormType = {
    name: string;
  };

  const editApplicationForm = useForm<EditApplicationFormType>({
    defaultValues: {
      name,
    },
    resolver: yupResolver(
      object().shape({
        name: string().trim().max(50).required().label("Application name"),
      })
    ),
  });

  const {
    register,
    formState: { errors },
  } = editApplicationForm;

  const { mutate: updateApplication, isLoading } =
    applications.useUpdateApplication(id);

  const handleSubmit = (data: EditApplicationFormType) => {
    updateApplication(data);
  };

  return (
    <Form
      form={editApplicationForm}
      onSubmit={handleSubmit}
      className={styles.form}
    >
      <TextField
        {...register("name")}
        className={styles.input}
        label="Application name"
        error={!!errors.name}
        helperText={errors.name?.message}
        variant="filled"
        disabled={isLoading}
      />
      <div>
        <Button type="submit" variant="contained" disabled={isLoading}>
          Save
        </Button>
      </div>
    </Form>
  );
};
