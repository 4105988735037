/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum DocumentType {
    PRIVACY_POLICY = 'privacy_policy',
    TERMS_AND_CONDITIONS = 'terms_and_conditions',
}