import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

import { EditProductForm } from "pages/EditProduct/EditProductForm";
import { ProductVersionsTable } from "pages/EditProduct/ProductVersionsTable";
import { ClientVersionsTable } from "pages/EditProduct/ClientVersionsTable";
import { useTitle } from "hooks/useAppTheme";
import { products } from "api/products";

import styles from "./EditProduct.module.scss";

enum TableTypes {
  PRODUCT_VERSIONS = "PRODUCT VERSIONS",
  CLIENT_VERSIONS = "CLIENT FIRMWARE VERSIONS",
}

const TABLE_TYPES = Object.entries(TableTypes).map(([_, value]) => value);

export const EditProduct = () => {
  useTitle("Edit Product");

  const [tableType, setTableType] = useState<TableTypes>(
    TableTypes.PRODUCT_VERSIONS
  );

  const { id }: any = useParams();
  const { data, isLoading } = products.useGetProduct(id);

  return (
    <>
      {data ? (
        <EditProductForm name={data.name} id={id} />
      ) : (
        <div className={styles.loadingForm}>
          <CircularProgress />
        </div>
      )}
      <Divider className={styles.divider} />
      <ToggleButtonGroup
        className={styles.toggle}
        exclusive
        value={tableType}
        onChange={(_, value: TableTypes) =>
          value !== null && setTableType(value)
        }
      >
        {TABLE_TYPES.map((name) => (
          <ToggleButton
            key={name}
            value={name}
            color="primary"
            className={styles.toggleButton}
          >
            {name}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      {isLoading ? (
        <CircularProgress className={styles.center} />
      ) : tableType === TableTypes.PRODUCT_VERSIONS ? (
        <ProductVersionsTable />
      ) : (
        <ClientVersionsTable />
      )}
    </>
  );
};
