// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FileInput_container__lYm6I {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  margin-bottom: 24px;\n}\n\n.FileInput_button__RdYJP {\n  margin-bottom: 12px;\n}\n\n.FileInput_fileName__LfOlX {\n  font-size: 14px;\n  white-space: nowrap;\n  overflow-x: hidden;\n  text-overflow: ellipsis;\n  max-width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/components/FileInput/FileInput.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,eAAA;EACA,mBAAA;EACA,kBAAA;EACA,uBAAA;EACA,eAAA;AACF","sourcesContent":[".container {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  margin-bottom: 24px;\n}\n\n.button {\n  margin-bottom: 12px;\n}\n\n.fileName {\n  font-size: 14px;\n  white-space: nowrap;\n  overflow-x: hidden;\n  text-overflow: ellipsis;\n  max-width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "FileInput_container__lYm6I",
	"button": "FileInput_button__RdYJP",
	"fileName": "FileInput_fileName__LfOlX"
};
export default ___CSS_LOADER_EXPORT___;
