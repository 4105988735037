import React, { useState } from "react";
import { Control, useController } from "react-hook-form";
import { Button, FormHelperText } from "@mui/material";

import styles from "./FileInput.module.scss";

type FileInputProps = {
  control: Control<any, object>;
  name: string;
  disabled: boolean;
  label?: string;
  errorMessage?: string;
};

export const FileInput = ({
  control,
  name,
  disabled,
  label = "Upload file",
  errorMessage,
}: FileInputProps) => {
  const { field } = useController({ control, name });

  const [value, setValue] = useState("");
  const [fileName, setFileName] = useState("");

  return (
    <div className={styles.container}>
      <Button
        variant="contained"
        component="label"
        className={styles.button}
        disabled={disabled}
      >
        {label}
        <input
          type="file"
          hidden
          value={value}
          onChange={(e) => {
            const file = e.target.files?.[0];

            field.onChange(file);
            setValue(e.target.value);
            setFileName(file?.name || "");
          }}
        />
      </Button>
      {fileName && <span className={styles.fileName}>{fileName}</span>}
      {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
    </div>
  );
};
