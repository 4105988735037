import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DateTimePicker from "react-datetime-picker";

import { DevicesTable } from "pages/Devices/DevicesTable";
import { useTitle } from "hooks/useAppTheme";
import { heaters } from "api/heaters";

import styles from "./Devices.module.scss";
import { CircularProgress } from "@mui/material";

const DEFAULT_FILTERS = {
  mac_startswith: "",
  from_datetime: new Date(),
  to_datetime: new Date(),
  limit: 10,
};

type FiltersTypes = {
  mac_startswith: string;
  from_datetime: Date | undefined;
  to_datetime: Date | undefined;
  limit: number;
};

export const Devices = () => {
  useTitle("Devices");

  const [internalFilters, setInternalFilters] =
    useState<FiltersTypes>(DEFAULT_FILTERS);

  const [appliedFilters, setAppliedFilters] =
    useState<FiltersTypes>(DEFAULT_FILTERS);

  const { data, isLoading } = heaters.useGetHeaters(appliedFilters);

  const applyFilters = () => {
    setAppliedFilters(internalFilters);
  };

  const clearFilters = () => {
    setInternalFilters(DEFAULT_FILTERS);
    setAppliedFilters(DEFAULT_FILTERS);
  };

  return (
    <div>
      <div className={styles.filters}>
        <div className={styles.row}>
          <TextField
            className={styles.rowItem}
            label="Mac"
            type="search"
            value={internalFilters.mac_startswith}
            onChange={(e) =>
              setInternalFilters({
                ...internalFilters,
                mac_startswith: e.target.value,
              })
            }
            variant="filled"
          />
          {/* There is no certainty as to how date filtering will work. This will have to be retouched */}
          <DateTimePicker
            className={styles.rowItem}
            value={internalFilters.from_datetime}
            onChange={(date) =>
              setInternalFilters({
                ...internalFilters,
                from_datetime: date,
                to_datetime: new Date(),
              })
            }
            clearIcon={null}
            disableClock
          />
        </div>
        <div className={styles.row}>
          <TextField
            className={styles.rowItem}
            type="number"
            label="Max entries"
            value={internalFilters.limit}
            onChange={(e) =>
              setInternalFilters({
                ...internalFilters,
                limit: e.target.value ? parseInt(e.target.value) : 10,
              })
            }
            variant="filled"
          />
        </div>
        <div className={styles.row}>
          <Button variant="contained" onClick={applyFilters}>
            Apply filters
          </Button>
          <Button variant="text" onClick={clearFilters}>
            Reset to default
          </Button>
        </div>
      </div>
      {isLoading ? (
        <CircularProgress className={styles.center} />
      ) : (
        data && <DevicesTable devices={data} />
      )}
    </div>
  );
};
