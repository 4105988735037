import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import { VersionsTable } from "pages/WRF/VersionsTable";
import { useTitle } from "hooks/useAppTheme";
import { routes } from "consts/routes";
import { wrf } from "api/wrf";

import styles from "./WRF.module.scss";

export const WRF = () => {
  useTitle("WRF Firmware Versions");

  const navigate = useNavigate();

  const { data, isLoading } = wrf.useGetWrfFirmwares();

  return (
    <>
      <Button
        className={styles.button}
        variant="contained"
        onClick={() => navigate(routes.WRF.UPLOAD)}
      >
        + Create WRF Firmware Version
      </Button>

      {isLoading ? (
        <CircularProgress className={styles.loading} />
      ) : (
        data && <VersionsTable data={data} />
      )}
    </>
  );
};
