import React from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import { Form } from "components/Form";
import { products } from "api/products";

import styles from "./EditProductForm.module.scss";

type EditProductFormProps = {
  name: string;
  id: string;
};

export const EditProductForm = ({ name, id }: EditProductFormProps) => {
  type EditProductFormType = {
    name: string;
  };

  const editProductForm = useForm<EditProductFormType>({
    defaultValues: {
      name,
    },
    resolver: yupResolver(
      object().shape({
        name: string().trim().max(50).required().label("Product name"),
      })
    ),
  });

  const {
    register,
    formState: { errors },
  } = editProductForm;

  const { mutate: updateProduct, isLoading } = products.useUpdateProduct(id);

  const handleSubmit = (data: EditProductFormType) => {
    updateProduct(data);
  };

  return (
    <Form
      form={editProductForm}
      onSubmit={handleSubmit}
      className={styles.form}
    >
      <TextField
        {...register("name")}
        className={styles.input}
        label="Product name"
        error={!!errors.name}
        helperText={errors.name?.message}
        variant="filled"
        disabled={isLoading}
      />
      <div>
        <Button type="submit" variant="contained" disabled={isLoading}>
          Save
        </Button>
      </div>
    </Form>
  );
};
