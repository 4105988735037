// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AppLayout_wrapper__B2Adk {\n  margin: 70px 0 16px 256px;\n  padding: 0 24px;\n}\n\n.AppLayout_mainContent__p1Jqh {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n\n.AppLayout_title__ugGWw {\n  font-size: 24px;\n  font-weight: bold;\n  margin-bottom: 38px;\n}", "",{"version":3,"sources":["webpack://./src/components/layout/AppLayout/AppLayout.module.scss"],"names":[],"mappings":"AAEA;EACE,yBAAA;EACA,eAAA;AADF;;AAIA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;AADF;;AAIA;EACE,eAAA;EACA,iBAAA;EACA,mBAAA;AADF","sourcesContent":["@use \"src/styles/vars\";\n\n.wrapper {\n  margin: calc(vars.$topbar-height + 22px) 0 16px vars.$side-menu-width;\n  padding: 0 24px;\n}\n\n.mainContent {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n\n.title {\n  font-size: 24px;\n  font-weight: bold;\n  margin-bottom: 38px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "AppLayout_wrapper__B2Adk",
	"mainContent": "AppLayout_mainContent__p1Jqh",
	"title": "AppLayout_title__ugGWw"
};
export default ___CSS_LOADER_EXPORT___;
