import { useMutation, useQuery, useQueryClient } from "react-query";

import { toast } from "components/Toast";
import { APIService } from "api/APIService";
import {
  DocumentOutputSchema,
  HTTPValidationError,
  ApplicationOutputSchema,
  DocumentLang,
  ApplicationChangeNameSchema,
} from "api/generated";
import { DEFAULT_STALE_TIME } from "consts/api";
import { resolveUrl } from "utils/urls";
import { urls } from "api/urls";
import { ErrorSchema } from "types/ErrorSchema";
import { EmptySchema } from "types/EmptySchema";

const useGetApplications = () => {
  return useQuery<ApplicationOutputSchema[]>(
    urls.APPS.LIST,
    () => APIService.get(urls.APPS.LIST),
    {
      staleTime: DEFAULT_STALE_TIME,
    }
  );
};

const useGetApplication = (id: string) => {
  return useQuery<ApplicationOutputSchema>(
    [urls.APPS.APPLICATION, { id }],
    () => APIService.get(resolveUrl(urls.APPS.APPLICATION, { id })),
    {
      refetchOnWindowFocus: false,
    }
  );
};

const useUpdateApplication = (id: string) => {
  const queryClient = useQueryClient();

  return useMutation<
    ApplicationOutputSchema,
    HTTPValidationError | ErrorSchema,
    ApplicationChangeNameSchema
  >(
    (data) => APIService.patch(resolveUrl(urls.APPS.APPLICATION, { id }), data),
    {
      onSuccess: (data) => {
        toast();
        queryClient.setQueryData([urls.APPS.APPLICATION, { id }], data);
        queryClient.setQueryData<ApplicationOutputSchema[]>(
          urls.APPS.LIST,
          (currentData = []) =>
            currentData.map((item) => (item.id === data.id ? data : item))
        );
      },
      onError: () => {
        toast("error");
      },
    }
  );
};

const useGetLanguages = () => {
  return useQuery<DocumentLang[]>(
    urls.APPS.DOCUMENTS_LANGUAGES,
    () => APIService.get(urls.APPS.DOCUMENTS_LANGUAGES),
    {
      staleTime: DEFAULT_STALE_TIME,
    }
  );
};

const useGetDocuments = () => {
  return useQuery<DocumentOutputSchema[]>(
    urls.APPS.DOCUMENTS.LIST,
    () => APIService.get(urls.APPS.DOCUMENTS.LIST),
    {
      staleTime: DEFAULT_STALE_TIME,
    }
  );
};

const useUploadDocument = () => {
  const queryClient = useQueryClient();

  return useMutation<
    DocumentOutputSchema,
    HTTPValidationError | ErrorSchema,
    FormData
  >(
    (data) =>
      APIService.post(urls.APPS.DOCUMENTS.LIST, data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      }),
    {
      onSuccess: () => {
        toast();
        queryClient.invalidateQueries(urls.APPS.DOCUMENTS.LIST);
      },
    }
  );
};

const useDeleteDocument = () => {
  const queryClient = useQueryClient();

  return useMutation<EmptySchema, HTTPValidationError | ErrorSchema, string>(
    (id) => APIService.delete(resolveUrl(urls.APPS.DOCUMENTS.DOCUMENT, { id })),
    {
      onSuccess: (_, id) => {
        toast();
        queryClient.invalidateQueries(urls.APPS.DOCUMENTS.LIST);
      },
      onError: () => {
        toast("error");
      },
    }
  );
};

export const applications = {
  useGetApplications,
  useGetApplication,
  useUpdateApplication,
  useGetLanguages,
  useGetDocuments,
  useUploadDocument,
  useDeleteDocument,
};
