// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Select_baseSelectContainer__EcXnN {\n  margin-bottom: 24px;\n  width: 100%;\n}\n\n.Select_label__NPl7G {\n  top: 16px;\n  left: -2px;\n}", "",{"version":3,"sources":["webpack://./src/components/Select/Select.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,WAAA;AACF;;AAEA;EACE,SAAA;EACA,UAAA;AACF","sourcesContent":[".baseSelectContainer {\n  margin-bottom: 24px;\n  width: 100%;\n}\n\n.label {\n  top: 16px;\n  left: -2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"baseSelectContainer": "Select_baseSelectContainer__EcXnN",
	"label": "Select_label__NPl7G"
};
export default ___CSS_LOADER_EXPORT___;
