// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UploadDocument_page__OMnVg {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n}\n\n.UploadDocument_content__jZYvT {\n  width: 100%;\n  display: flex;\n}\n\n.UploadDocument_form__85rcA {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  max-width: 420px;\n  width: 100%;\n  margin-right: 24px;\n}\n\n.UploadDocument_input__9aUhG {\n  margin-bottom: 24px;\n  height: 48px;\n}\n\n.UploadDocument_divider__8B7E\\+ {\n  width: 100%;\n  margin-bottom: 32px;\n}\n\n.UploadDocument_error__wj4K0 {\n  color: #da1e28;\n  font-size: 14px;\n}", "",{"version":3,"sources":["webpack://./src/pages/UploadDocument/UploadDocument.module.scss","webpack://./src/styles/colors/_index.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;AADF;;AAIA;EACE,WAAA;EACA,aAAA;AADF;;AAIA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,gBAAA;EACA,WAAA;EACA,kBAAA;AADF;;AAIA;EACE,mBAAA;EACA,YAAA;AADF;;AAIA;EACE,WAAA;EACA,mBAAA;AADF;;AAIA;EACE,cC3BM;ED4BN,eAAA;AADF","sourcesContent":["@use \"src/styles/colors\";\n\n.page {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n}\n\n.content {\n  width: 100%;\n  display: flex;\n}\n\n.form {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  max-width: 420px;\n  width: 100%;\n  margin-right: 24px;\n}\n\n.input {\n  margin-bottom: 24px;\n  height: 48px;\n}\n\n.divider {\n  width: 100%;\n  margin-bottom: 32px;\n}\n\n.error {\n  color: colors.$error;\n  font-size: 14px;\n}\n","$white: #fff;\n$hint: #666666;\n$black: #000;\n\n$primary-mui: #1976d2;\n\n$error: #da1e28;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": "UploadDocument_page__OMnVg",
	"content": "UploadDocument_content__jZYvT",
	"form": "UploadDocument_form__85rcA",
	"input": "UploadDocument_input__9aUhG",
	"divider": "UploadDocument_divider__8B7E+",
	"error": "UploadDocument_error__wj4K0"
};
export default ___CSS_LOADER_EXPORT___;
