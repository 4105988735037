// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ConfirmationDialog_container__7\\+UFz {\n  padding: 24px;\n}\n\n.ConfirmationDialog_title__Bf4yj {\n  font-weight: bold;\n  padding: 0;\n  margin-bottom: 24px;\n}\n\n.ConfirmationDialog_message__shZgM {\n  font-size: 14px;\n  margin-bottom: 48px;\n}\n\n.ConfirmationDialog_content__wToHx {\n  padding: 0;\n}\n\n.ConfirmationDialog_actions__RC\\+aa {\n  padding: 0;\n}", "",{"version":3,"sources":["webpack://./src/components/ConfirmationDialog/ConfirmationDialog.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;AADF;;AAIA;EACE,iBAAA;EACA,UAAA;EACA,mBAAA;AADF;;AAIA;EACE,eAAA;EACA,mBAAA;AADF;;AAIA;EACE,UAAA;AADF;;AAIA;EACE,UAAA;AADF","sourcesContent":["@use \"src/styles/colors\";\n\n.container {\n  padding: 24px;\n}\n\n.title {\n  font-weight: bold;\n  padding: 0;\n  margin-bottom: 24px;\n}\n\n.message {\n  font-size: 14px;\n  margin-bottom: 48px;\n}\n\n.content {\n  padding: 0;\n}\n\n.actions {\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ConfirmationDialog_container__7+UFz",
	"title": "ConfirmationDialog_title__Bf4yj",
	"message": "ConfirmationDialog_message__shZgM",
	"content": "ConfirmationDialog_content__wToHx",
	"actions": "ConfirmationDialog_actions__RC+aa"
};
export default ___CSS_LOADER_EXPORT___;
