export const urls = {
  USERS: {
    LIST: "/users",
    USER: "/users/:id",
    CHANGE_EMAIL: "/users/:id/change_email",
  },
  PRODUCTS: {
    LIST: "/products",
    PRODUCT: "/products/:id",
    CLIENT_FIRMWARES: {
      LIST: "/products/:id/client_firmware",
      FIRMWARE: "/products/:id/client_firmware/:clientId",
    },
    PRODUCT_VERSIONS: {
      LIST: "/products/:id/versions",
      VERSION: "/products/:id/versions/:productVersionId",
    },
  },
  UPGRADE_GROUPS: {
    LIST: "/upgrade_groups",
    GROUP: "/upgrade_groups/:id",
  },
  HEATERS: {
    LIST: "/heaters",
  },
  APPS: {
    LIST: "/applications",
    APPLICATION: "/applications/:id",
    DOCUMENTS: {
      LIST: "/documents",
      DOCUMENT: "/documents/:id",
    },
    DOCUMENTS_LANGUAGES: "/documents/languages",
  },
  WRF: {
    LIST: "/wrf_firmware",
    FIRMWARE: "/wrf_firmware/:id",
  },
};
