import React, { useState } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import {
  Button,
  ButtonBase,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
} from "@mui/material";
import cx from "clsx";

import { products } from "api/products";
import { ConfirmationDialog } from "components/ConfirmationDialog";
import { routes } from "consts/routes";
import { resolveUrl } from "utils/urls";

import styles from "./ProductVersionsTable.module.scss";

const HEADERS = [
  "Product Version",
  "Client Firmware Version",
  "WRF Firmware Version",
  "Options",
];

// TODO replace IDs in table for actual names when API changed
export const ProductVersionsTable = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [versionToDelete, setVersionToDelete] = useState("");

  const handleDialogOpen = (productVersionId: string) => {
    setVersionToDelete(productVersionId);
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setVersionToDelete("");
    setIsDialogOpen(false);
  };

  const handleDialogConfirm = () => {
    deleteProductVersion({ id, productVersionId: versionToDelete });
    handleDialogClose();
  };

  const { id }: any = useParams();

  const { data, isLoading } = products.useGetProductVersions(id);

  const { mutate: deleteProductVersion } = products.useDeleteProductVersion();

  return (
    <>
      <h2 className={styles.title}>Product versions</h2>
      <RouterLink
        to={resolveUrl(routes.PRODUCTS.EDIT.PRODUCT_VERSIONS.CREATE, { id })}
        className={styles.action}
      >
        <Button className={styles.actionButton} variant="contained">
          + Create product version
        </Button>
      </RouterLink>
      {isLoading ? (
        <CircularProgress className={styles.center} />
      ) : (
        <Table>
          <TableHead>
            <TableRow classes={{ root: cx(styles.head, styles.row) }}>
              {HEADERS.map((label, i) => (
                <TableCell className={styles.header} key={i}>
                  {label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map(
              ({ id, version_name, client_firmware, wrf_firmware }, i) => (
                <TableRow classes={{ root: styles.row }} key={i}>
                  <TableCell className={styles.cell}>{version_name}</TableCell>
                  <TableCell className={styles.cell}>
                    {client_firmware.version_name}
                  </TableCell>
                  <TableCell className={styles.cell}>
                    {wrf_firmware.version_name}
                  </TableCell>
                  <TableCell className={styles.cell}>
                    <ButtonBase
                      classes={{ root: styles.button }}
                      onClick={() => handleDialogOpen(id!)}
                      disableRipple
                    >
                      Delete
                    </ButtonBase>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      )}
      <ConfirmationDialog
        title="Delete product version"
        message="Note that if this Product Version is currently being used in any heater group(s), it will result in those groups being removed as well. Are you sure you want to delete this Product Version?"
        isOpen={isDialogOpen}
        onCancel={handleDialogClose}
        onConfirm={handleDialogConfirm}
      />
    </>
  );
};
