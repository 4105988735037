import { useQuery, useMutation, useQueryClient } from "react-query";

import { toast } from "components/Toast";
import { APIService } from "api/APIService";
import { urls } from "api/urls";
import { ApiRole, User, HTTPValidationError } from "api/generated";
import { DEFAULT_STALE_TIME } from "consts/api";
import { EmptySchema } from "types/EmptySchema";
import { ErrorSchema } from "types/ErrorSchema";
import { resolveUrl } from "utils/urls";

type UseGetUsersParams = {
  email_start?: string;
  limit?: number;
  role?: ApiRole.ADMIN;
};

const useGetUsers = ({ email_start, limit, role }: UseGetUsersParams) => {
  return useQuery<User[]>(
    [urls.USERS.LIST, { email_start, limit, role }],
    () =>
      APIService.get(
        resolveUrl(urls.USERS.LIST, {}, { email_start, limit, role })
      ),
    {
      staleTime: DEFAULT_STALE_TIME,
    }
  );
};

const useGetUser = (id: string) => {
  return useQuery<User>(
    [urls.USERS.USER, { id }],
    () => APIService.get(resolveUrl(urls.USERS.USER, { id })),
    {
      refetchOnWindowFocus: false,
    }
  );
};

const useDeleteUser = () => {
  const queryClient = useQueryClient();

  return useMutation<EmptySchema, HTTPValidationError | ErrorSchema, string>(
    (id) => APIService.delete(resolveUrl(urls.USERS.USER, { id })),
    {
      onSuccess: () => {
        toast();
        queryClient.invalidateQueries(urls.USERS.LIST);
      },
      onError: () => {
        toast("error");
      },
    }
  );
};

const useChangeUserEmail = (id: string) => {
  return useMutation<EmptySchema, HTTPValidationError | ErrorSchema, string>(
    (email) =>
      APIService.patch(resolveUrl(urls.USERS.CHANGE_EMAIL, { id }), { email }),
    {
      onSuccess: () => {
        toast();
      },
      onError: () => {
        toast("error");
      },
    }
  );
};

export const users = {
  useGetUsers,
  useGetUser,
  useDeleteUser,
  useChangeUserEmail,
};
