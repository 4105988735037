import React from "react";
import { Controller, Control, useFormContext } from "react-hook-form";
import {
  FormControl,
  FormHelperText,
  Select as MUISelect,
  InputLabel,
  MenuItem,
  SelectProps,
} from "@mui/material";
import cx from "clsx";

import styles from "./Select.module.scss";

// TODO add Select wrapped with react-hook-form
type Item = {
  value: string;
  label?: string;
};

interface BaseSelectProps extends SelectProps<string> {
  value: string;
  labelText: string;
  disabled: boolean;
  items?: Item[];
}

interface SelectWithControllerProps extends Omit<BaseSelectProps, "value"> {
  errorMessage?: string;
  control: Control<any, object>;
  name: string;
}

export const SelectWithController = ({
  errorMessage,
  labelText,
  disabled,
  items,
  control,
  name,
  ...rest
}: SelectWithControllerProps) => {
  const { watch } = useFormContext();

  const hasValue = !!watch(name);

  return (
    <FormControl
      className={styles.baseSelectContainer}
      error={!!errorMessage}
      disabled={disabled || !items}
    >
      <InputLabel
        id={`${name}-label`}
        classes={{ focused: styles.label }}
        className={cx({ [styles.label]: hasValue })}
      >
        {labelText}
      </InputLabel>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <MUISelect
            labelId={`${name}-label`}
            variant="filled"
            {...rest}
            {...field}
          >
            {items?.map(({ label, value }) => (
              <MenuItem value={value} key={value}>
                {label || value}
              </MenuItem>
            ))}
          </MUISelect>
        )}
      />
      {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
    </FormControl>
  );
};

export const BaseSelect = ({
  value,
  labelId,
  labelText,
  disabled,
  items,
  ...rest
}: BaseSelectProps) => (
  <FormControl
    className={styles.baseSelectContainer}
    disabled={disabled || !items}
  >
    <InputLabel
      id={labelId}
      classes={{ focused: styles.label }}
      className={cx({ [styles.label]: !!value })}
    >
      {labelText}
    </InputLabel>
    <MUISelect labelId={labelId} value={value} variant="filled" {...rest}>
      {items?.map(({ label, value }) => (
        <MenuItem value={value} key={value}>
          {label || value}
        </MenuItem>
      ))}
    </MUISelect>
  </FormControl>
);
