import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { ToastContainer } from "react-toastify";

import { AppTitleProvider } from "hooks/useAppTheme";
import { msalConfig } from "api/authConfig";
import { theme } from "./theme";
import Routes from "./Routes";

const queryClient = new QueryClient();

export const msalInstance = new PublicClientApplication(msalConfig);

export const Providers = () => {
  return (
    <MsalProvider instance={msalInstance}>
      <QueryClientProvider client={queryClient}>
        <ToastContainer />
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <AppTitleProvider>
              <CssBaseline />
              <GlobalStyles
                styles={{
                  body: { backgroundColor: "#f4f4f4" },
                }}
              />
              <Router>
                <Routes />
              </Router>
            </AppTitleProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </QueryClientProvider>
    </MsalProvider>
  );
};
