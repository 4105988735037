import { Configuration, LogLevel } from "@azure/msal-browser";

export const b2cPolicies = {
  names: {
    signIn: process.env.REACT_APP_B2CPOLICIES_NAMES_SIGN_IN,
  },
  authorities: {
    signIn: {
      authority:
        process.env.REACT_APP_B2CPOLICIES_AUTHORITIES_SIGN_IN_AUTHORITY,
    },
    signUp: {
      authority:
        process.env.REACT_APP_B2CPOLICIES_AUTHORITIES_SIGN_UP_AUTHORITY,
    },
  },
  authorityDomain: process.env.REACT_APP_B2CPOLICIES_AUTHORITY_DOMAIN,
};

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_B2C_CLIENT_ID!, // This is the ONLY mandatory field that you need to supply.
    authority: b2cPolicies.authorities.signIn.authority, // Choose SUSI as your default authority.
    knownAuthorities: [b2cPolicies.authorityDomain!], // Mark your B2C tenant's domain as trusted.
    redirectUri: process.env.REACT_APP_MSAL_AUTH_REDIRECT_URI, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: ["https://behav2dev.onmicrosoft.com/beha-backend-dev/admin-access"],
};
