import { useQuery } from "react-query";

import { APIService } from "api/APIService";
import { HeaterOutputSchema } from "api/generated";
import { urls } from "api/urls";
import { DEFAULT_STALE_TIME } from "consts/api";
import { resolveUrl } from "utils/urls";
import { formatDateToRawISOString } from "utils/dates";

type GetHeatersParams = {
  mac_startswith?: string;
  product_name?: string;
  from_datetime?: Date;
  to_datetime?: Date;
  limit?: number;
};

const useGetHeaters = ({
  mac_startswith,
  product_name,
  from_datetime,
  to_datetime,
  limit,
}: GetHeatersParams) => {
  const from = from_datetime
    ? formatDateToRawISOString(from_datetime)
    : from_datetime;
  const to = to_datetime ? formatDateToRawISOString(to_datetime) : to_datetime;
  return useQuery<HeaterOutputSchema[]>(
    [
      urls.HEATERS.LIST,
      {
        mac_startswith,
        product_name,
        from_datetime: from,
        to_datetime: to,
        limit,
      },
    ],
    () =>
      APIService.get(
        resolveUrl(
          urls.HEATERS.LIST,
          {},
          {
            mac_startswith,
            product_name,
            from_datetime: from,
            to_datetime: to,
            limit,
          }
        )
      ),
    {
      staleTime: DEFAULT_STALE_TIME,
    }
  );
};

export const heaters = { useGetHeaters };
