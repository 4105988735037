import React from "react";
import { FormProvider, SubmitHandler, UseFormReturn } from "react-hook-form";

interface FormProps<T>
  extends Omit<React.HTMLProps<HTMLFormElement>, "onSubmit" | "form"> {
  form: UseFormReturn<T>;
  onSubmit: SubmitHandler<T>;
  children: React.ReactNode;
}

export const Form = <T,>({
  form,
  onSubmit,
  children,
  ...rest
}: FormProps<T>) => {
  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} {...rest}>
        {children}
      </form>
    </FormProvider>
  );
};
