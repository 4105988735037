import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Divider, TextField } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";

import { toast } from "components/Toast";
import { wrf } from "api/wrf";
import { products } from "api/products";
import { ProductVersionInputSchema } from "api/generated";
import { SelectWithController } from "components/Select";
import { Form } from "components/Form";
import { useTitle } from "hooks/useAppTheme";
import { routes } from "consts/routes";
import { resolveUrl } from "utils/urls";
import { parseApiError } from "utils/apiResponses";
import { getErrorMessage } from "pages/CreateProductVersion/utils";

import styles from "./CreateProductVersion.module.scss";

export const CreateProductVersion = () => {
  useTitle("Create Product Version");

  const createProductVersionForm = useForm<ProductVersionInputSchema>({
    defaultValues: {
      version_name: "",
      client_firmware_id: "",
      wrf_firmware_id: "",
    },
    resolver: yupResolver(
      object().shape({
        version_name: string()
          .trim()
          .max(50)
          .required()
          .label("Product version name"),
        wrf_firmware_id: string().required().label("WRF firmware version"),
        client_firmware_id: string()
          .trim()
          .max(50)
          .required()
          .label("Client firmware version"),
      })
    ),
  });

  const {
    formState: { errors },
    register,
    control,
  } = createProductVersionForm;

  const { id }: any = useParams();

  const { data: wrfFirmwares, isLoading: isFetchingWrfFirmwares } =
    wrf.useGetWrfFirmwares();

  const { data: clientFirmwares, isLoading: isFetchingClientFirmwares } =
    products.useGetClientFirmwares(id);

  const { mutate: createProductVersion, isLoading } =
    products.useCreateProductVersion(id);

  const navigate = useNavigate();

  const handleSubmit = (data: ProductVersionInputSchema) => {
    createProductVersion(data, {
      onSuccess: () => navigate(resolveUrl(routes.PRODUCTS.EDIT.ROOT, { id })),
      onError: (error) => toast("error", getErrorMessage(parseApiError(error))),
    });
  };

  return (
    <div>
      <Button
        className={styles.cancel}
        onClick={() => navigate(resolveUrl(routes.PRODUCTS.EDIT.ROOT, { id }))}
      >
        Back to edit product
      </Button>
      <Divider className={styles.divider} />
      <Form
        className={styles.form}
        form={createProductVersionForm}
        onSubmit={handleSubmit}
      >
        <TextField
          {...register("version_name")}
          className={styles.input}
          variant="filled"
          label="Product version name"
          fullWidth
          error={!!errors.version_name}
          helperText={errors.version_name?.message}
        />

        <SelectWithController
          labelText="WRF Firmware Version"
          name="wrf_firmware_id"
          disabled={isFetchingWrfFirmwares || isLoading}
          control={control}
          errorMessage={errors.wrf_firmware_id?.message}
          items={wrfFirmwares?.map(({ id, version_name }) => {
            return { label: version_name, value: id };
          })}
        />
        <SelectWithController
          labelText="Client Firmware"
          name="client_firmware_id"
          disabled={isFetchingClientFirmwares || isLoading}
          control={control}
          errorMessage={errors.client_firmware_id?.message}
          items={clientFirmwares?.map(({ id, version_name }) => {
            return { label: version_name, value: id };
          })}
        />
        <Button
          type="submit"
          variant="contained"
          className={styles.saveButton}
          disabled={isLoading}
        >
          Create
        </Button>
      </Form>
    </div>
  );
};
