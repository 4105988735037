// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EditProduct_divider__bODQL {\n  margin-bottom: 24px;\n}\n\n.EditProduct_loadingForm__Fnfgr {\n  display: flex;\n  justify-content: center;\n  max-width: 420px;\n  width: 100%;\n  margin-bottom: 24px;\n}\n\n.EditProduct_center__NRy7n {\n  align-self: center;\n}\n\n.EditProduct_toggle__7zpVM {\n  margin-bottom: 32px;\n}\n\n.EditProduct_toggleButton__szP0h {\n  height: 36px;\n  font-weight: bold;\n}", "",{"version":3,"sources":["webpack://./src/pages/EditProduct/EditProduct.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,gBAAA;EACA,WAAA;EACA,mBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,YAAA;EACA,iBAAA;AACF","sourcesContent":[".divider {\n  margin-bottom: 24px;\n}\n\n.loadingForm {\n  display: flex;\n  justify-content: center;\n  max-width: 420px;\n  width: 100%;\n  margin-bottom: 24px;\n}\n\n.center {\n  align-self: center;\n}\n\n.toggle {\n  margin-bottom: 32px;\n}\n\n.toggleButton {\n  height: 36px;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"divider": "EditProduct_divider__bODQL",
	"loadingForm": "EditProduct_loadingForm__Fnfgr",
	"center": "EditProduct_center__NRy7n",
	"toggle": "EditProduct_toggle__7zpVM",
	"toggleButton": "EditProduct_toggleButton__szP0h"
};
export default ___CSS_LOADER_EXPORT___;
