import axios from "axios";

import { msalInstance } from "base/Providers";
import { loginRequest } from "api/authConfig";
import { routes } from "consts/routes";

function fetchAccessToken() {
  const accounts = msalInstance.getAllAccounts();
  const account = accounts[0];

  return new Promise((resolve) => {
    msalInstance
      .acquireTokenSilent({ ...loginRequest, account: account })
      .then((response) => {
        resolve(response.accessToken);
      })
      .catch((_) => {
        msalInstance.logoutPopup({ postLogoutRedirectUri: routes.LOGIN });
      });
  });
}

// TODO tweak URLs later when BE deployed (DEV vs PROD)
const URL = process.env.REACT_APP_API_URL;

export const APIService = axios.create({
  baseURL: URL,
});

APIService.interceptors.request.use(
  async (config) => {
    const accessToken = await fetchAccessToken();

    if (accessToken) {
      config!.headers!.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => {
    Promise.reject(error.response?.data);
  }
);

APIService.interceptors.response.use(
  (response) => response.data,
  (error) => Promise.reject(error.response?.data)
);
