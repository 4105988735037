import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

import { ApplicationsTable } from "pages/Applications/ApplicationsTable";
import { applications } from "api/applications";
import { useTitle } from "hooks/useAppTheme";

import styles from "./Applications.module.scss";

export const Applications = () => {
  useTitle("Applications");

  const { isLoading, data } = applications.useGetApplications();

  return (
    <>
      {isLoading ? (
        <CircularProgress className={styles.loading} />
      ) : (
        data && <ApplicationsTable data={data} />
      )}
    </>
  );
};
