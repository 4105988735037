import React from "react";
import { Routes as RouterRoutes, Route, Navigate } from "react-router-dom";

import { AppLayout } from "components/layout/AppLayout";
import { RequireAuth, PublicOnly } from "base/RouteTypes";
import { routes } from "consts/routes";
import { Login } from "pages/Login";
import { Products } from "pages/Products";
import { EditProduct } from "pages/EditProduct";
import { CreateClientVersion } from "pages/CreateClientVersion";
import { Applications } from "pages/Applications";
import { EditApplication } from "pages/EditApplication";
import { Users } from "pages/Users";
import { Devices } from "pages/Devices";
import { DeviceLogs } from "pages/DeviceLogs";
import { EditUser } from "pages/EditUser";
import { UploadDocument } from "pages/UploadDocument";
import { WRF } from "pages/WRF";
import { CreateProductVersion } from "pages/CreateProductVersion/CreateProductVersion";
import { UpgradeGroups } from "pages/UpgradeGroups";
import { RequestUpgrade } from "pages/RequestUpgrade";
import { UploadWRF } from "pages/UploadWRF";

// TODO -- this routing is not too good, especially with the new RR changes. Might wanna improve it.

// Use PublicOnly for routes which should be available only for logged out users
// Use RequireAuth for routes which should be available only for logged in users
// Otherwise, proceed without any wrappers
export const Routes = () => (
  <RouterRoutes>
    <Route
      path={routes.LOGIN}
      element={
        <PublicOnly>
          <Login />
        </PublicOnly>
      }
    />
    <Route
      path="*"
      element={
        <RequireAuth>
          <Navigate to={routes.PRODUCTS.ROOT} />
        </RequireAuth>
      }
    />
    <Route
      path={routes.PRODUCTS.ROOT}
      element={
        <RequireAuth>
          <AppLayout>
            <Products />
          </AppLayout>
        </RequireAuth>
      }
    />
    <Route
      path={routes.PRODUCTS.EDIT.ROOT}
      element={
        <RequireAuth>
          <AppLayout>
            <EditProduct />
          </AppLayout>
        </RequireAuth>
      }
    />
    <Route
      path={routes.PRODUCTS.EDIT.CLIENT_VERSIONS.CREATE}
      element={
        <RequireAuth>
          <AppLayout>
            <CreateClientVersion />
          </AppLayout>
        </RequireAuth>
      }
    />
    <Route
      path={routes.PRODUCTS.EDIT.PRODUCT_VERSIONS.CREATE}
      element={
        <RequireAuth>
          <AppLayout>
            <CreateProductVersion />
          </AppLayout>
        </RequireAuth>
      }
    />
    <Route
      path={routes.USERS.ROOT}
      element={
        <RequireAuth>
          <AppLayout>
            <Users />
          </AppLayout>
        </RequireAuth>
      }
    />
    <Route
      path={routes.APPLICATIONS.ROOT}
      element={
        <RequireAuth>
          <AppLayout>
            <Applications />
          </AppLayout>
        </RequireAuth>
      }
    />
    <Route
      path={routes.APPLICATIONS.EDIT.ROOT}
      element={
        <RequireAuth>
          <AppLayout>
            <EditApplication />
          </AppLayout>
        </RequireAuth>
      }
    />
    <Route
      path={routes.APPLICATIONS.EDIT.UPLOAD_DOCUMENT}
      element={
        <RequireAuth>
          <AppLayout>
            <UploadDocument />
          </AppLayout>
        </RequireAuth>
      }
    />
    <Route
      path={routes.USERS.EDIT}
      element={
        <RequireAuth>
          <AppLayout>
            <EditUser />
          </AppLayout>
        </RequireAuth>
      }
    />
    <Route
      path={routes.DEVICES.ROOT}
      element={
        <RequireAuth>
          <AppLayout>
            <Devices />
          </AppLayout>
        </RequireAuth>
      }
    />
    <Route
      path={routes.DEVICES.LOGS}
      element={
        <RequireAuth>
          <AppLayout>
            <DeviceLogs />
          </AppLayout>
        </RequireAuth>
      }
    />
    <Route
      path={routes.UPGRADE.ROOT}
      element={
        <RequireAuth>
          <AppLayout>
            <UpgradeGroups />
          </AppLayout>
        </RequireAuth>
      }
    />
    <Route
      path={routes.UPGRADE.REQUEST}
      element={
        <RequireAuth>
          <AppLayout>
            <RequestUpgrade />
          </AppLayout>
        </RequireAuth>
      }
    />
    <Route
      path={routes.WRF.ROOT}
      element={
        <RequireAuth>
          <AppLayout>
            <WRF />
          </AppLayout>
        </RequireAuth>
      }
    />
    <Route
      path={routes.WRF.UPLOAD}
      element={
        <RequireAuth>
          <AppLayout>
            <UploadWRF />
          </AppLayout>
        </RequireAuth>
      }
    />
  </RouterRoutes>
);

export default Routes;
