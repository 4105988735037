import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import Button from "@mui/material/Button";

import cssVars from "styles/vars.module.scss";

import styles from "./DocumentViewer.module.scss";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type DocumentViewerProps = {
  file: File;
};

const OPTIONS = {
  cMapUrl: "cmaps/",
  cMapPacked: true,
};

export const DocumentViewer = ({ file }: DocumentViewerProps) => {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({
    numPages: nextNumPages,
  }: {
    numPages: number;
  }) => {
    setNumPages(nextNumPages);
  };

  return (
    <div className={styles.container}>
      <Document
        file={file}
        noData={""}
        onLoadSuccess={onDocumentLoadSuccess}
        options={OPTIONS}
        loading=""
      >
        <Page
          width={parseInt(
            cssVars.documentWidth.substring(0, cssVars.documentWidth.length - 2)
          )}
          pageNumber={pageNumber}
          loading=""
        />
      </Document>

      <div className={styles.navigationContainer}>
        <Button
          className={styles.previous}
          disabled={pageNumber <= 1}
          onClick={() =>
            setPageNumber((prevPageNumber: number) => --prevPageNumber)
          }
          variant="contained"
        >
          {"<"}
        </Button>
        <p className={styles.pageNumber}>{`${pageNumber}/${numPages}`}</p>
        <Button
          disabled={pageNumber >= numPages!}
          onClick={() =>
            setPageNumber((prevPageNumber: number) => ++prevPageNumber)
          }
          variant="contained"
        >
          {">"}
        </Button>
      </div>
    </div>
  );
};
