import { msalInstance } from "base/Providers";
import { routes } from "consts/routes";

const useLogout = () => {
  msalInstance.logoutRedirect({ postLogoutRedirectUri: routes.LOGIN });
};

export const auth = {
  useLogout,
};
