export const routes = {
  LOGIN: "/login",
  PRODUCTS: {
    ROOT: "/products",
    EDIT: {
      ROOT: "/products/:id",
      PRODUCT_VERSIONS: {
        EDIT: "/products/:id/product-versions/edit/:versionId",
        CREATE: "/products/:id/product-versions/create",
      },
      CLIENT_VERSIONS: {
        CREATE: "/products/:id/client-versions/create",
      },
    },
  },
  APPLICATIONS: {
    ROOT: "/applications",
    EDIT: {
      ROOT: "/applications/:id",
      UPLOAD_DOCUMENT: "/applications/:id/upload",
    },
  },
  USERS: {
    ROOT: "/users",
    EDIT: "/users/:id",
  },
  DEVICES: {
    ROOT: "/devices",
    LOGS: "/devices/:id",
  },
  UPGRADE: {
    ROOT: "/upgrade",
    REQUEST: "/upgrade/request",
  },
  WRF: {
    ROOT: "/wrf",
    UPLOAD: "/wrf/upload",
  },
};
