import { compile } from "path-to-regexp";
import qs from "qs";

type Primitive = string | boolean | number;
export interface QueryToParse {
  [key: string]:
    | Primitive
    | Primitive[]
    | QueryToParse
    | QueryToParse[]
    | null
    | undefined;
}

export const resolveUrl = <P extends object = object>(
  endpoint: string,
  params?: P,
  query?: QueryToParse
) => {
  const toPath = compile<P>(endpoint, { encode: encodeURIComponent });
  const parsedEndpoint = toPath(params);
  const parsedQuery = qs.stringify(query, {
    addQueryPrefix: true,
    skipNulls: true,
  });
  return `${parsedEndpoint}${parsedQuery}`;
};
