import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import { toast } from "components/Toast";
import { FileInput } from "components/FileInput";
import { useTitle } from "hooks/useAppTheme";
import { resolveUrl } from "utils/urls";
import { routes } from "consts/routes";
import { products } from "api/products";
import { parseApiError } from "utils/apiResponses";
import { getErrorMessage } from "pages/CreateClientVersion/utils";

import styles from "./CreateClientVersion.module.scss";
import { Form } from "components/Form";

export const CreateClientVersion = () => {
  useTitle("Create Client Firmware Version");

  const { id }: any = useParams();

  const { mutate: createClientVersion, isLoading } =
    products.useCreateClientFirmware(id);

  type CreateClientVersionFormType = {
    version_name: string;
    file: File;
  };

  const createClientVersionForm = useForm<CreateClientVersionFormType>({
    defaultValues: {
      version_name: "",
    },
    resolver: yupResolver(
      object().shape({
        version_name: string().trim().max(50).required().label("Version name"),
        file: mixed().required().label("File"),
      })
    ),
  });

  const {
    control,
    formState: { errors },
    register,
  } = createClientVersionForm;

  const navigate = useNavigate();

  const handleSubmit = ({
    file,
    version_name,
  }: CreateClientVersionFormType) => {
    const formData = new FormData();

    formData.append("file", file as Blob);
    formData.append("version_name", version_name);

    createClientVersion(formData, {
      onSuccess: () => navigate(resolveUrl(routes.PRODUCTS.EDIT.ROOT, { id })),
      onError: (error) => toast("error", getErrorMessage(parseApiError(error))),
    });
  };

  return (
    <div>
      <Button
        className={styles.cancel}
        onClick={() => navigate(resolveUrl(routes.PRODUCTS.EDIT.ROOT, { id }))}
      >
        Back to edit product
      </Button>
      <Divider className={styles.divider} />
      <Form
        form={createClientVersionForm}
        className={styles.form}
        onSubmit={handleSubmit}
      >
        <FileInput
          name="file"
          errorMessage={errors.file?.message}
          control={control}
          label="Upload file"
          disabled={isLoading}
        />
        <TextField
          {...register("version_name")}
          className={styles.input}
          variant="filled"
          label="Client firmware version name"
          error={!!errors.version_name}
          helperText={errors.version_name?.message}
          disabled={isLoading}
          fullWidth
        />
        <Button
          type="submit"
          variant="contained"
          disabled={isLoading}
          fullWidth
        >
          Create
        </Button>
      </Form>
    </div>
  );
};
