import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import { Button, Divider } from "@mui/material";

import { toast } from "components/Toast";
import { parseApiError } from "utils/apiResponses";
import { Form } from "components/Form";
import { FileInput } from "components/FileInput";
import { SelectWithController } from "components/Select";
import { DocumentViewer } from "pages/UploadDocument/DocumentViewer";
import { getErrorMessage } from "pages/UploadDocument/utils";
import { useTitle } from "hooks/useAppTheme";
import { resolveUrl } from "utils/urls";
import { routes } from "consts/routes";
import { applications } from "api/applications";
import { DocumentType } from "api/generated";

import styles from "./UploadDocument.module.scss";

export const UploadDocument = () => {
  useTitle("Upload Document");

  const { id }: any = useParams();

  const navigate = useNavigate();

  const { mutate: uploadDocument, isLoading } =
    applications.useUploadDocument();

  const { data: languages, isLoading: isFetchingLanguages } =
    applications.useGetLanguages();

  type UploadDocumentType = {
    lang: string;
    document_type: string;
    file: File;
  };

  const uploadDocumentForm = useForm<UploadDocumentType>({
    defaultValues: {
      lang: "",
      document_type: "",
    },
    resolver: yupResolver(
      object().shape({
        lang: string().required().label("Language"),
        file: mixed().required().label("File"),
        document_type: string().required().label("Document type"),
      })
    ),
  });

  const {
    control,
    formState: { errors },
    watch,
  } = uploadDocumentForm;

  const handleSubmit = ({ document_type, lang, file }: UploadDocumentType) => {
    const formData = new FormData();
    formData.append("application_id", id);
    formData.append("lang", lang);
    formData.append("document_type", document_type);
    formData.append("file", file as Blob);

    uploadDocument(formData, {
      onSuccess: () =>
        navigate(resolveUrl(routes.APPLICATIONS.EDIT.ROOT, { id })),
      onError: (error) => toast("error", getErrorMessage(parseApiError(error))),
    });
  };

  const file = watch("file");

  return (
    <div className={styles.page}>
      <Button
        className={styles.cancel}
        onClick={() =>
          navigate(resolveUrl(routes.APPLICATIONS.EDIT.ROOT, { id }))
        }
      >
        Back to edit application
      </Button>
      <Divider className={styles.divider} />
      <div className={styles.content}>
        <Form
          className={styles.form}
          form={uploadDocumentForm}
          onSubmit={handleSubmit}
        >
          <FileInput
            name="file"
            errorMessage={errors.file?.message}
            control={control}
            label="Upload file"
            disabled={isLoading}
          />
          <SelectWithController
            labelText="Language"
            disabled={isLoading || isFetchingLanguages}
            name="lang"
            errorMessage={errors.lang?.message}
            items={languages?.map((value) => ({ value, label: value }))}
            control={control}
          />
          <SelectWithController
            labelText="Document type"
            disabled={isLoading}
            name="document_type"
            errorMessage={errors.document_type?.message}
            items={[
              { label: "Privacy Policy", value: DocumentType.PRIVACY_POLICY },
              {
                label: "Terms and Conditions",
                value: DocumentType.TERMS_AND_CONDITIONS,
              },
            ]}
            control={control}
          />
          <Button
            type="submit"
            variant="contained"
            disabled={isLoading}
            fullWidth
          >
            Create
          </Button>
        </Form>
        {file && <DocumentViewer file={file} />}
      </div>
    </div>
  );
};
