// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WRF_button__FhRLQ {\n  margin-bottom: 32px;\n  margin-left: auto;\n  text-decoration: none;\n}\n\n.WRF_loading__iq-cS {\n  align-self: center;\n}", "",{"version":3,"sources":["webpack://./src/pages/WRF/WRF.module.scss"],"names":[],"mappings":"AAGA;EACE,mBAAA;EACA,iBAAA;EACA,qBAAA;AAFF;;AAKA;EACE,kBAAA;AAFF","sourcesContent":["@use \"src/styles/font/family\" as f;\n@use \"src/styles/colors\";\n\n.button {\n  margin-bottom: 32px;\n  margin-left: auto;\n  text-decoration: none;\n}\n\n.loading {\n  align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "WRF_button__FhRLQ",
	"loading": "WRF_loading__iq-cS"
};
export default ___CSS_LOADER_EXPORT___;
