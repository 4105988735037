import React from "react";
import Button from "@mui/material/Button";
import { useMsal } from "@azure/msal-react";

import { Topbar } from "components/Topbar";
import { b2cPolicies, loginRequest } from "api/authConfig";

import styles from "./Login.module.scss";

export const Login = () => {
  const { instance } = useMsal();

  const handleLogin = (instance: any) => {
    instance.loginRedirect(loginRequest).catch((e: any) => {
      console.error(e);
    });
  };

  const handleRegister = (instance: any) => {
    instance.loginRedirect(b2cPolicies.authorities.signUp).catch((e: any) => {
      console.error(e);
    });
  };

  return (
    <>
      <Topbar />
      <div className={styles.page}>
        <div className={styles.buttonsContainer}>
          <Button
            classes={{ root: styles.button }}
            variant="contained"
            onClick={() => handleLogin(instance)}
          >
            Sign in
          </Button>
          <p>or</p>
          <Button
            classes={{ root: styles.button }}
            variant="contained"
            onClick={() => handleRegister(instance)}
          >
            Register
          </Button>
        </div>
      </div>
    </>
  );
};
