// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RequestUpgrade_form__o3\\+1U {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  max-width: 420px;\n  width: 100%;\n  margin-right: 24px;\n}\n\n.RequestUpgrade_divider__v80id {\n  width: 100%;\n  margin-bottom: 32px;\n}\n\n.RequestUpgrade_input__lTFJi {\n  margin-bottom: 24px;\n}\n\n.RequestUpgrade_hint__3SCwW {\n  color: #666666;\n  font-size: 10px;\n}", "",{"version":3,"sources":["webpack://./src/pages/RequestUpgrade/RequestUpgrade.module.scss","webpack://./src/styles/colors/_index.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,gBAAA;EACA,WAAA;EACA,kBAAA;AADF;;AAIA;EACE,WAAA;EACA,mBAAA;AADF;;AAIA;EACE,mBAAA;AADF;;AAIA;EACE,cCpBK;EDqBL,eAAA;AADF","sourcesContent":["@use \"src/styles/colors\";\n\n.form {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  max-width: 420px;\n  width: 100%;\n  margin-right: 24px;\n}\n\n.divider {\n  width: 100%;\n  margin-bottom: 32px;\n}\n\n.input {\n  margin-bottom: 24px;\n}\n\n.hint {\n  color: colors.$hint;\n  font-size: 10px;\n}\n","$white: #fff;\n$hint: #666666;\n$black: #000;\n\n$primary-mui: #1976d2;\n\n$error: #da1e28;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "RequestUpgrade_form__o3+1U",
	"divider": "RequestUpgrade_divider__v80id",
	"input": "RequestUpgrade_input__lTFJi",
	"hint": "RequestUpgrade_hint__3SCwW"
};
export default ___CSS_LOADER_EXPORT___;
