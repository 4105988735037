import React from "react";

import { SideMenu } from "components/SideMenu";
import { Topbar } from "components/Topbar";
import { useAppTheme } from "hooks/useAppTheme";

import styles from "./AppLayout.module.scss";

type AppLayoutProps = {
  children: React.ReactNode;
};

export const AppLayout = ({ children }: AppLayoutProps) => {
  const { title } = useAppTheme();

  return (
    <div>
      <Topbar />
      <SideMenu />
      <div className={styles.wrapper}>
        <h1 className={styles.title}>{title}</h1>
        <main className={styles.mainContent}>{children}</main>
      </div>
    </div>
  );
};
