import React, { useState } from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import CircularProgress from "@mui/material/CircularProgress";

import { UsersTable } from "./UsersTable";
import { useTitle } from "hooks/useAppTheme";
import { users } from "api/users";
import { ApiRole } from "api/generated";
import debounce from "utils/debounce";

import styles from "./Users.module.scss";

const USER_TYPES = [
  {
    value: "",
    label: "ALL",
  },
  {
    value: ApiRole.ADMIN,
    label: "ADMINS",
  },
];

export const Users = () => {
  useTitle("Users");
  // internal input value
  const [internalSearchValue, setInternalSearchValue] = useState("");
  // debounced value that's applied to useGetUsers query
  const [querySearchValue, setQuerySearchValue] = useState("");
  const [searchUserTypes, setSearchUserTypes] = useState<ApiRole.ADMIN | "">(
    ""
  );
  const { data, isLoading } = users.useGetUsers({
    email_start: querySearchValue || undefined,
    role: searchUserTypes || undefined,
  });

  const updateSearch = React.useMemo(
    () => debounce((value: string) => setQuerySearchValue(value), 500),
    []
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInternalSearchValue(e.target.value);
    updateSearch(e.target.value);
  };

  return (
    <div className={styles.page}>
      <FormControl className={styles.inputContainer}>
        <TextField
          className={styles.input}
          fullWidth
          type="search"
          label="E-mail"
          value={internalSearchValue}
          onChange={handleSearchChange}
          variant="filled"
        />
      </FormControl>
      <ToggleButtonGroup
        className={styles.toggle}
        exclusive
        value={searchUserTypes}
        onChange={(_, value: ApiRole.ADMIN | "") =>
          value !== null && setSearchUserTypes(value)
        }
      >
        {USER_TYPES.map(({ label, value }) => (
          <ToggleButton
            key={value}
            value={value}
            color="primary"
            className={styles.toggleButton}
          >
            {label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      {isLoading ? (
        <CircularProgress className={styles.loading} />
      ) : (
        data && <UsersTable data={data} />
      )}
    </div>
  );
};
