import React from "react";
import { toast as rootToast, TypeOptions, ToastContent } from "react-toastify";

export const toast = (type?: TypeOptions, message?: string) => {
  const typeToPass = type || "success";

  const content = getToastMessage(typeToPass, message);
  rootToast(content, { type: typeToPass, theme: "colored" });
};

const getToastMessage = (
  type?: TypeOptions,
  message?: string
): ToastContent => {
  if (message) {
    return message;
  } else {
    if (!type || type === "success") {
      return "Success!";
    } else if (type === "error") {
      return "Something went wrong!";
    } else {
      return "Action registered!";
    }
  }
};
