// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Devices_filters__NJ2AI {\n  display: flex;\n  flex-direction: column;\n  max-width: 596px;\n  width: 100%;\n  margin-bottom: 32px;\n}\n\n.Devices_row__dVKNI {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 24px;\n}\n.Devices_row__dVKNI:last-child {\n  margin-bottom: 0;\n}\n\n.Devices_rowItem__vafwF {\n  max-width: 228px;\n  width: 100%;\n}\n\n.Devices_label__36szB {\n  top: 16px;\n  left: -2px;\n}\n\n.Devices_center__SLL5I {\n  align-self: center;\n}", "",{"version":3,"sources":["webpack://./src/pages/Devices/Devices.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,WAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AACF;AACE;EACE,gBAAA;AACJ;;AAGA;EACE,gBAAA;EACA,WAAA;AAAF;;AAGA;EACE,SAAA;EACA,UAAA;AAAF;;AAGA;EACE,kBAAA;AAAF","sourcesContent":[".filters {\n  display: flex;\n  flex-direction: column;\n  max-width: 596px;\n  width: 100%;\n  margin-bottom: 32px;\n}\n\n.row {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 24px;\n\n  &:last-child {\n    margin-bottom: 0;\n  }\n}\n\n.rowItem {\n  max-width: 228px;\n  width: 100%;\n}\n\n.label {\n  top: 16px;\n  left: -2px;\n}\n\n.center {\n  align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filters": "Devices_filters__NJ2AI",
	"row": "Devices_row__dVKNI",
	"rowItem": "Devices_rowItem__vafwF",
	"label": "Devices_label__36szB",
	"center": "Devices_center__SLL5I"
};
export default ___CSS_LOADER_EXPORT___;
