// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DocumentViewer_container__tcvpL {\n  position: relative;\n  width: 540px;\n}\n\n.DocumentViewer_navigationContainer__iHE-w {\n  position: absolute;\n  top: 0;\n  right: 0;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n  height: 48px;\n  padding: 8px;\n  background: #fff;\n  opacity: 0.6;\n  transition: opacity 0.15s ease-in-out;\n}\n.DocumentViewer_navigationContainer__iHE-w:hover {\n  opacity: 1;\n}\n\n.DocumentViewer_previous__kKAHb,\n.DocumentViewer_pageNumber__JBmLa {\n  margin-right: 8px;\n}", "",{"version":3,"sources":["webpack://./src/pages/UploadDocument/DocumentViewer/DocumentViewer.module.scss","webpack://./src/styles/colors/_index.scss"],"names":[],"mappings":"AAGA;EACE,kBAAA;EACA,YAAA;AAFF;;AAKA;EACE,kBAAA;EACA,MAAA;EACA,QAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EACA,gBClBM;EDmBN,YAAA;EACA,qCAAA;AAFF;AAIE;EACE,UAAA;AAFJ;;AAMA;;EAEE,iBAAA;AAHF","sourcesContent":["@use \"src/styles/colors\";\n@use \"src/styles/vars\";\n\n.container {\n  position: relative;\n  width: vars.$document-width;\n}\n\n.navigationContainer {\n  position: absolute;\n  top: 0;\n  right: 0;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n  height: 48px;\n  padding: 8px;\n  background: colors.$white;\n  opacity: 0.6;\n  transition: opacity 0.15s ease-in-out;\n\n  &:hover {\n    opacity: 1;\n  }\n}\n\n.previous,\n.pageNumber {\n  margin-right: 8px;\n}\n","$white: #fff;\n$hint: #666666;\n$black: #000;\n\n$primary-mui: #1976d2;\n\n$error: #da1e28;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "DocumentViewer_container__tcvpL",
	"navigationContainer": "DocumentViewer_navigationContainer__iHE-w",
	"previous": "DocumentViewer_previous__kKAHb",
	"pageNumber": "DocumentViewer_pageNumber__JBmLa"
};
export default ___CSS_LOADER_EXPORT___;
