// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EditApplication_divider__K6-Ww {\n  margin-bottom: 24px;\n}\n\n.EditApplication_title__K1Msi {\n  margin: 0 0 24px;\n}\n\n.EditApplication_createButton__vYcPi {\n  margin-bottom: 24px;\n  margin-left: auto;\n}\n\n.EditApplication_link__rP0Pq {\n  display: flex;\n  align-self: flex-end;\n  text-decoration: none;\n  color: #000;\n}\n\n.EditApplication_loadingForm__dQRz4 {\n  display: flex;\n  justify-content: center;\n  max-width: 420px;\n  width: 100%;\n  margin-bottom: 24px;\n}\n\n.EditApplication_center__4RtOH {\n  align-self: center;\n}", "",{"version":3,"sources":["webpack://./src/pages/EditApplication/EditApplication.module.scss","webpack://./src/styles/colors/_index.scss"],"names":[],"mappings":"AAEA;EACE,mBAAA;AADF;;AAIA;EACE,gBAAA;AADF;;AAIA;EACE,mBAAA;EACA,iBAAA;AADF;;AAIA;EACE,aAAA;EACA,oBAAA;EACA,qBAAA;EACA,WCjBM;ADgBR;;AAIA;EACE,aAAA;EACA,uBAAA;EACA,gBAAA;EACA,WAAA;EACA,mBAAA;AADF;;AAIA;EACE,kBAAA;AADF","sourcesContent":["@use \"src/styles/colors\";\n\n.divider {\n  margin-bottom: 24px;\n}\n\n.title {\n  margin: 0 0 24px;\n}\n\n.createButton {\n  margin-bottom: 24px;\n  margin-left: auto;\n}\n\n.link {\n  display: flex;\n  align-self: flex-end;\n  text-decoration: none;\n  color: colors.$black;\n}\n\n.loadingForm {\n  display: flex;\n  justify-content: center;\n  max-width: 420px;\n  width: 100%;\n  margin-bottom: 24px;\n}\n\n.center {\n  align-self: center;\n}\n","$white: #fff;\n$hint: #666666;\n$black: #000;\n\n$primary-mui: #1976d2;\n\n$error: #da1e28;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"divider": "EditApplication_divider__K6-Ww",
	"title": "EditApplication_title__K1Msi",
	"createButton": "EditApplication_createButton__vYcPi",
	"link": "EditApplication_link__rP0Pq",
	"loadingForm": "EditApplication_loadingForm__dQRz4",
	"center": "EditApplication_center__4RtOH"
};
export default ___CSS_LOADER_EXPORT___;
