// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CreateProductVersion_form__VQ3RB {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  max-width: 420px;\n  width: 100%;\n  margin-bottom: 24px;\n}\n\n.CreateProductVersion_input__n1Erd {\n  margin-bottom: 24px;\n}\n\n.CreateProductVersion_saveButton__4VBiJ {\n  margin-right: 16px;\n}\n\n.CreateProductVersion_cancel__obAKc {\n  text-decoration: none;\n}\n\n.CreateProductVersion_divider__w51a9 {\n  width: 100%;\n  margin-bottom: 32px;\n}", "",{"version":3,"sources":["webpack://./src/pages/CreateProductVersion/CreateProductVersion.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,gBAAA;EACA,WAAA;EACA,mBAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,qBAAA;AACF;;AAEA;EACE,WAAA;EACA,mBAAA;AACF","sourcesContent":[".form {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  max-width: 420px;\n  width: 100%;\n  margin-bottom: 24px;\n}\n\n.input {\n  margin-bottom: 24px;\n}\n\n.saveButton {\n  margin-right: 16px;\n}\n\n.cancel {\n  text-decoration: none;\n}\n\n.divider {\n  width: 100%;\n  margin-bottom: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "CreateProductVersion_form__VQ3RB",
	"input": "CreateProductVersion_input__n1Erd",
	"saveButton": "CreateProductVersion_saveButton__4VBiJ",
	"cancel": "CreateProductVersion_cancel__obAKc",
	"divider": "CreateProductVersion_divider__w51a9"
};
export default ___CSS_LOADER_EXPORT___;
