import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  typography: {
    fontFamily: "IBMPlexSans-Regular, Arial",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          letterSpacing: "1.25px",
          fontFamily: "IBMPlexSans-Bold",
        },
      },
    },
  },
});
