import React from "react";
import { Link, useParams } from "react-router-dom";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import { Button } from "@mui/material";

import { routes } from "consts/routes";
import { resolveUrl } from "utils/urls";
import { EditApplicationForm } from "pages/EditApplication/EditApplicationForm";
import { DocumentsTable } from "pages/EditApplication/DocumentsTable";
import { useTitle } from "hooks/useAppTheme";

import styles from "./EditApplication.module.scss";
import { applications } from "api/applications";

export const EditApplication = () => {
  useTitle("Edit Application");

  const { id }: any = useParams();
  const { data, isLoading } = applications.useGetApplication(id);
  const { data: documents } = applications.useGetDocuments();

  return (
    <>
      {data ? (
        <EditApplicationForm name={data.name} id={id} />
      ) : (
        <div className={styles.loadingForm}>
          <CircularProgress />
        </div>
      )}
      <Divider className={styles.divider} />
      <h2 className={styles.title}>Documents</h2>
      <Link
        to={resolveUrl(routes.APPLICATIONS.EDIT.UPLOAD_DOCUMENT, { id })}
        className={styles.link}
      >
        <Button
          className={styles.createButton}
          variant="contained"
          disabled={isLoading}
        >
          + Upload new document
        </Button>
      </Link>
      {isLoading ? (
        <CircularProgress className={styles.center} />
      ) : (
        documents && <DocumentsTable data={documents} />
      )}
    </>
  );
};
