export const getErrorMessage = (message: string) => {
  switch (message) {
    case "Object with one or more passed fields (required as unique) already exist":
      return "Product version with such name and/or WRF and Client Firmware Versions combination already exists.";
    case "field required":
      return "Some fields are missing.";
    default:
      return message;
  }
};
