import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Button,
  CircularProgress,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import cx from "clsx";

import { routes } from "consts/routes";
import { upgradeGroups } from "api/upgradeGroups";
import { useTitle } from "hooks/useAppTheme";

import styles from "./UpgradeGroups.module.scss";

const HEADERS = [
  "Release Group Name",
  "Product Version",
  "Upgrade Date",
  "Options",
];

// TODO missing data after API figured out
export const UpgradeGroups = () => {
  useTitle("Upgrade");

  const { data, isLoading } = upgradeGroups.useGetUpgradeGroups();

  console.log(data);

  return (
    <>
      <RouterLink to={routes.UPGRADE.REQUEST} className={styles.action}>
        <Button className={styles.actionButton} variant="contained">
          + New upgrade group
        </Button>
      </RouterLink>
      {isLoading ? (
        <CircularProgress className={styles.center} />
      ) : (
        <Table>
          <TableHead>
            <TableRow classes={{ root: cx(styles.head, styles.row) }}>
              {HEADERS.map((label, i) => (
                <TableCell className={styles.header} key={i}>
                  {label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map(({ name, product_version, request_date, url }, i) => (
              <TableRow classes={{ root: styles.row }} key={i}>
                <TableCell className={styles.cell}>{name}</TableCell>
                <TableCell className={styles.cell}>
                  {product_version.version_name}
                </TableCell>
                <TableCell className={styles.cell}>{request_date}</TableCell>
                <TableCell className={styles.cell}>
                  <Link
                    target="_blank"
                    rel="noreferrer"
                    href={url}
                    download
                    className={styles.link}
                    underline="none"
                  >
                    Download
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
};
