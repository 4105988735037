import React from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, CircularProgress, TextField } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";

import { users } from "api/users";
import { useTitle } from "hooks/useAppTheme";
import { Form } from "components/Form";
import { UpdateEmailSchema } from "api/generated";

import styles from "./EditUser.module.scss";

export const EditUser = () => {
  useTitle("Edit User");

  const { id }: any = useParams();
  const { data: userData } = users.useGetUser(id);
  const { mutate: changeUserEmail, isLoading: isChangeEmailSubmitting } =
    users.useChangeUserEmail(id);

  const handleSubmit = (data: UpdateEmailSchema) => {
    changeUserEmail(data.email);
  };

  return (
    <div className={styles.formContainer}>
      {userData ? (
        <ChangeEmailForm
          defaultValue={userData.email}
          onSubmit={handleSubmit}
          disabled={isChangeEmailSubmitting}
        />
      ) : (
        <CircularProgress />
      )}
    </div>
  );
};

type ChangeEmailFormProps = {
  defaultValue?: string;
  onSubmit: (data: UpdateEmailSchema) => void;
  disabled: boolean;
};

const ChangeEmailForm = ({
  defaultValue,
  onSubmit,
  disabled,
}: ChangeEmailFormProps) => {
  const editUserForm = useForm<UpdateEmailSchema>({
    defaultValues: {
      email: defaultValue,
    },
    resolver: yupResolver(
      object().shape({
        email: string().required().label("Email"),
      })
    ),
  });

  const {
    register,
    formState: { errors },
  } = editUserForm;

  return (
    <Form
      className={styles.changeEmail}
      form={editUserForm}
      onSubmit={onSubmit}
    >
      <p className={styles.subtitle}>Change e-mail</p>
      <TextField
        {...register("email")}
        className={styles.emailInput}
        fullWidth
        disabled={disabled}
        label="Email"
        variant="filled"
        type="email"
        error={!!errors.email}
        helperText={errors.email?.message}
      />

      <Button type="submit" variant="contained" disabled={disabled}>
        Change
      </Button>
    </Form>
  );
};
