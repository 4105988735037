// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Users_page__2fcRk {\n  display: flex;\n  flex-direction: column;\n}\n\n.Users_inputContainer__\\+X97I {\n  display: flex;\n  align-items: center;\n  max-width: 512px;\n  width: 100%;\n  margin-bottom: 32px;\n}\n\n.Users_input__nHcse {\n  height: 48px;\n}\n\n.Users_toggle__hq5h- {\n  margin-bottom: 32px;\n}\n\n.Users_toggleButton__RMj2N {\n  height: 36px;\n  font-weight: bold;\n}\n\n.Users_loading__oqIh4 {\n  align-self: center;\n}", "",{"version":3,"sources":["webpack://./src/pages/Users/Users.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,WAAA;EACA,mBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,YAAA;EACA,iBAAA;AACF;;AAEA;EACE,kBAAA;AACF","sourcesContent":[".page {\n  display: flex;\n  flex-direction: column;\n}\n\n.inputContainer {\n  display: flex;\n  align-items: center;\n  max-width: 512px;\n  width: 100%;\n  margin-bottom: 32px;\n}\n\n.input {\n  height: 48px;\n}\n\n.toggle {\n  margin-bottom: 32px;\n}\n\n.toggleButton {\n  height: 36px;\n  font-weight: bold;\n}\n\n.loading {\n  align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": "Users_page__2fcRk",
	"inputContainer": "Users_inputContainer__+X97I",
	"input": "Users_input__nHcse",
	"toggle": "Users_toggle__hq5h-",
	"toggleButton": "Users_toggleButton__RMj2N",
	"loading": "Users_loading__oqIh4"
};
export default ___CSS_LOADER_EXPORT___;
