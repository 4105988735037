import { useQuery, useMutation, useQueryClient } from "react-query";

import { toast } from "components/Toast";
import { APIService } from "api/APIService";
import {
  Product,
  ProductUpdateSchema,
  ProductVersionInputSchema,
  ProductVersionOutputSchema,
  HTTPValidationError,
  ClientFirmwareOutputSchema,
} from "api/generated";
import { urls } from "api/urls";
import { DEFAULT_STALE_TIME } from "consts/api";
import { resolveUrl } from "utils/urls";
import { ErrorSchema } from "types/ErrorSchema";
import { EmptySchema } from "types/EmptySchema";

const useGetProducts = () => {
  return useQuery<Product[]>(
    urls.PRODUCTS.LIST,
    () => APIService.get(urls.PRODUCTS.LIST),
    {
      staleTime: DEFAULT_STALE_TIME,
    }
  );
};

const useGetProduct = (id: string) => {
  return useQuery<Product>(
    [urls.PRODUCTS.PRODUCT, { id }],
    () => APIService.get(resolveUrl(urls.PRODUCTS.PRODUCT, { id })),
    {
      refetchOnWindowFocus: false,
    }
  );
};

const useUpdateProduct = (id: string) => {
  const queryClient = useQueryClient();

  return useMutation<
    Product,
    HTTPValidationError | ErrorSchema,
    ProductUpdateSchema
  >(
    (data) => APIService.patch(resolveUrl(urls.PRODUCTS.PRODUCT, { id }), data),
    {
      onSuccess: () => {
        toast();
        queryClient.invalidateQueries(urls.PRODUCTS.LIST);
      },
      onError: () => {
        toast("error");
      },
    }
  );
};

const useGetClientFirmwares = (id: string) => {
  return useQuery<ClientFirmwareOutputSchema[]>(
    urls.PRODUCTS.CLIENT_FIRMWARES.LIST,
    () =>
      APIService.get(resolveUrl(urls.PRODUCTS.CLIENT_FIRMWARES.LIST, { id })),
    {
      staleTime: DEFAULT_STALE_TIME,
    }
  );
};

type GetClientFirmwareParams = {
  id: string;
  clientId: string;
};

const useCreateClientFirmware = (id: string) => {
  const queryClient = useQueryClient();

  return useMutation<
    ClientFirmwareOutputSchema,
    HTTPValidationError | ErrorSchema,
    FormData
  >(
    (data) =>
      APIService.post(
        resolveUrl(urls.PRODUCTS.CLIENT_FIRMWARES.LIST, { id }),
        data
      ),
    {
      onSuccess: () => {
        toast();
        queryClient.invalidateQueries(urls.PRODUCTS.CLIENT_FIRMWARES.LIST);
      },
    }
  );
};

const useDeleteClientFirmware = () => {
  const queryClient = useQueryClient();

  return useMutation<
    EmptySchema,
    HTTPValidationError | ErrorSchema,
    GetClientFirmwareParams
  >(
    (params) =>
      APIService.delete(
        resolveUrl(urls.PRODUCTS.CLIENT_FIRMWARES.FIRMWARE, params)
      ),
    {
      onSuccess: () => {
        toast();
        queryClient.invalidateQueries(urls.PRODUCTS.CLIENT_FIRMWARES.LIST);
      },
      onError: () => {
        toast("error");
      },
    }
  );
};

type ProductVersionParams = {
  id: string;
  productVersionId: string;
};

const useGetProductVersions = (id: string) => {
  return useQuery<ProductVersionOutputSchema[]>(
    urls.PRODUCTS.PRODUCT_VERSIONS.LIST,
    () =>
      APIService.get(resolveUrl(urls.PRODUCTS.PRODUCT_VERSIONS.LIST, { id })),
    {
      staleTime: DEFAULT_STALE_TIME,
      enabled: !!id,
    }
  );
};

const useCreateProductVersion = (id: string) => {
  const queryClient = useQueryClient();

  return useMutation<
    ProductVersionOutputSchema,
    HTTPValidationError | ErrorSchema,
    ProductVersionInputSchema
  >(
    (data) =>
      APIService.post(
        resolveUrl(urls.PRODUCTS.PRODUCT_VERSIONS.LIST, { id }),
        data
      ),
    {
      onSuccess: () => {
        toast();
        queryClient.invalidateQueries(urls.PRODUCTS.PRODUCT_VERSIONS.LIST);
      },
    }
  );
};

const useDeleteProductVersion = () => {
  const queryClient = useQueryClient();

  return useMutation<
    EmptySchema,
    HTTPValidationError | ErrorSchema,
    ProductVersionParams
  >(
    (params) =>
      APIService.delete(
        resolveUrl(urls.PRODUCTS.PRODUCT_VERSIONS.VERSION, params)
      ),
    {
      onSuccess: () => {
        toast();
        queryClient.invalidateQueries(urls.PRODUCTS.PRODUCT_VERSIONS.LIST);
      },
      onError: () => toast("error"),
    }
  );
};

export const products = {
  useGetProducts,
  useGetProduct,
  useUpdateProduct,
  useGetClientFirmwares,
  useCreateClientFirmware,
  useDeleteClientFirmware,
  useGetProductVersions,
  useCreateProductVersion,
  useDeleteProductVersion,
};
