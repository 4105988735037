import React from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, mixed } from "yup";
import { Button, TextField } from "@mui/material";

import { toast } from "components/Toast";
import { Form } from "components/Form";
import { FileInput } from "components/FileInput";
import { routes } from "consts/routes";
import { useTitle } from "hooks/useAppTheme";
import { wrf, WrfFirmwareInputSchema } from "api/wrf";
import { parseApiError } from "utils/apiResponses";
import { getErrorMessage } from "pages/UploadWRF/utils";

import styles from "./UploadWRF.module.scss";

export const UploadWRF = () => {
  useTitle("Create WRF Firmware Version");

  const uploadWrfForm = useForm<WrfFirmwareInputSchema>({
    defaultValues: {
      version_name: "",
    },
    resolver: yupResolver(
      object().shape({
        version_name: string().trim().max(50).required().label("Version name"),
        file: mixed().required().label("File"),
      })
    ),
  });

  const {
    control,
    register,
    formState: { errors },
  } = uploadWrfForm;

  const { mutate: uploadWrfFirmware, isLoading } = wrf.useUploadWrfFirmware();

  const navigate = useNavigate();

  const handleSubmit = (data: WrfFirmwareInputSchema) => {
    uploadWrfFirmware(wrf.parseWrfFormForApi(data), {
      onSuccess: () => {
        navigate(routes.WRF.ROOT);
      },
      onError: (error) => toast("error", getErrorMessage(parseApiError(error))),
    });
  };

  return (
    <Form className={styles.form} form={uploadWrfForm} onSubmit={handleSubmit}>
      <TextField
        {...register("version_name")}
        className={styles.input}
        label="Version name"
        error={!!errors.version_name}
        helperText={errors.version_name?.message}
        variant="filled"
        disabled={isLoading}
      />
      <FileInput
        control={control}
        name="file"
        disabled={isLoading}
        errorMessage={errors.file?.message}
      />
      <Button type="submit" variant="contained" disabled={isLoading}>
        Create
      </Button>
    </Form>
  );
};
