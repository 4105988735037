// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EditUser_formContainer__CYGgw {\n  display: flex;\n  justify-content: center;\n  max-width: 420px;\n  width: 100%;\n  margin-bottom: 24px;\n}\n\n.EditUser_changeEmail__4AAkg {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  width: 100%;\n}\n\n.EditUser_subtitle__iElZm {\n  font-weight: bold;\n}\n\n.EditUser_emailInput__-HXnE {\n  margin-bottom: 24px;\n}", "",{"version":3,"sources":["webpack://./src/pages/EditUser/EditUser.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,gBAAA;EACA,WAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,WAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,mBAAA;AACF","sourcesContent":[".formContainer {\n  display: flex;\n  justify-content: center;\n  max-width: 420px;\n  width: 100%;\n  margin-bottom: 24px;\n}\n\n.changeEmail {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  width: 100%;\n}\n\n.subtitle {\n  font-weight: bold;\n}\n\n.emailInput {\n  margin-bottom: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formContainer": "EditUser_formContainer__CYGgw",
	"changeEmail": "EditUser_changeEmail__4AAkg",
	"subtitle": "EditUser_subtitle__iElZm",
	"emailInput": "EditUser_emailInput__-HXnE"
};
export default ___CSS_LOADER_EXPORT___;
