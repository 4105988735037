import { routes } from "./routes";

import { ReactComponent as Products } from "assets/icons/icon-products.svg";
import { ReactComponent as Applications } from "assets/icons/icon-applications.svg";
import { ReactComponent as Users } from "assets/icons/icon-users.svg";
import { ReactComponent as Devices } from "assets/icons/icon-devices.svg";
import { ReactComponent as Upgrade } from "assets/icons/icon-upgrade.svg";
import { ReactComponent as WRF } from "assets/icons/icon-wrf.svg";

export const NAV_ITEMS = [
  {
    text: "Products",
    to: routes.PRODUCTS.ROOT,
    icon: <Products />,
    subsectionName: "products",
  },
  {
    text: "Applications",
    to: routes.APPLICATIONS.ROOT,
    icon: <Applications />,
    subsectionName: "applications",
  },
  {
    text: "Users",
    to: routes.USERS.ROOT,
    icon: <Users />,
    subsectionName: "users",
  },
  {
    text: "Devices",
    to: routes.DEVICES.ROOT,
    icon: <Devices />,
    subsectionName: "devices",
  },
  {
    text: "Upgrade",
    to: routes.UPGRADE.ROOT,
    icon: <Upgrade />,
    subsectionName: "upgrade",
  },
  {
    text: "WRF Firmware",
    to: routes.WRF.ROOT,
    icon: <WRF />,
    subsectionName: "wrf",
  },
];
