import React, { useState } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import {
  Button,
  ButtonBase,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import cx from "clsx";

import { ConfirmationDialog } from "components/ConfirmationDialog";
import { routes } from "consts/routes";
import { resolveUrl } from "utils/urls";
import { products } from "api/products";

import styles from "./ClientVersionsTable.module.scss";

const HEADERS = ["Name", " Options"];

export const ClientVersionsTable = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [versionToDelete, setVersionToDelete] = useState("");

  const { id }: any = useParams();

  const { data, isLoading } = products.useGetClientFirmwares(id);

  const { mutate: deleteClientFirmware } = products.useDeleteClientFirmware();

  const handleDialogOpen = (clientId: string) => {
    setVersionToDelete(clientId);
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setVersionToDelete("");
    setIsDialogOpen(false);
  };

  const handleDialogConfirm = () => {
    deleteClientFirmware({ id, clientId: versionToDelete });
    handleDialogClose();
  };

  return (
    <>
      <h2 className={styles.title}>Client firmware versions</h2>
      <RouterLink
        to={resolveUrl(routes.PRODUCTS.EDIT.CLIENT_VERSIONS.CREATE, { id })}
        className={styles.action}
      >
        <Button className={styles.actionButton} variant="contained">
          + Create client firmware version
        </Button>
      </RouterLink>
      {isLoading ? (
        <CircularProgress className={styles.center} />
      ) : (
        <Table>
          <TableHead>
            <TableRow classes={{ root: cx(styles.head, styles.row) }}>
              {HEADERS.map((label, i) => (
                <TableCell className={styles.header} key={i}>
                  {label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map(({ version_name, id: clientId }, i) => (
              <TableRow classes={{ root: styles.row }} key={i}>
                <TableCell className={styles.cell}>{version_name}</TableCell>
                <TableCell className={styles.cell}>
                  <ButtonBase
                    classes={{ root: styles.button }}
                    onClick={() => handleDialogOpen(clientId)}
                    disableRipple
                  >
                    Delete
                  </ButtonBase>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      <ConfirmationDialog
        title="Delete Client Firmware Version"
        message="Note that if this Client Firmware Version is currently being used in any product version or heater group, it will result in those product versions and/or groups being removed as well. Are you sure you want to delete this Client Firmware Version?"
        isOpen={isDialogOpen}
        onCancel={handleDialogClose}
        onConfirm={handleDialogConfirm}
      />
    </>
  );
};
