/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export { ApiRole } from './models/ApiRole';
export type { ApplicationChangeNameSchema } from './models/ApplicationChangeNameSchema';
export type { ApplicationDocumentsLangsOutputSchema } from './models/ApplicationDocumentsLangsOutputSchema';
export type { ApplicationOutputSchema } from './models/ApplicationOutputSchema';
export type { Body_create_upgrade_group_and_request_upgrade_upgrade_groups_post } from './models/Body_create_upgrade_group_and_request_upgrade_upgrade_groups_post';
export type { Body_upload_client_firmware_products__product_id__client_firmware_post } from './models/Body_upload_client_firmware_products__product_id__client_firmware_post';
export type { Body_upload_document_documents_post } from './models/Body_upload_document_documents_post';
export type { Body_upload_wrf_firmware_wrf_firmware_post } from './models/Body_upload_wrf_firmware_wrf_firmware_post';
export type { ClientFirmware } from './models/ClientFirmware';
export type { ClientFirmwareOutputSchema } from './models/ClientFirmwareOutputSchema';
export type { CreateUserContinueOutputSchema } from './models/CreateUserContinueOutputSchema';
export type { CreateUserSchema } from './models/CreateUserSchema';
export { DocumentLang } from './models/DocumentLang';
export type { DocumentOutputSchema } from './models/DocumentOutputSchema';
export { DocumentType } from './models/DocumentType';
export type { Heater } from './models/Heater';
export type { HeaterChangeRoomInputSchema } from './models/HeaterChangeRoomInputSchema';
export type { HeaterEnabledStateInputSchema } from './models/HeaterEnabledStateInputSchema';
export type { HeaterLockStateInputSchema } from './models/HeaterLockStateInputSchema';
export type { HeaterOutputSchema } from './models/HeaterOutputSchema';
export type { HTTPValidationError } from './models/HTTPValidationError';
export type { PlaceInputSchema } from './models/PlaceInputSchema';
export type { PlaceOutputSchema } from './models/PlaceOutputSchema';
export type { PlaceWithRoomsOutputSchema } from './models/PlaceWithRoomsOutputSchema';
export type { Product } from './models/Product';
export type { ProductUpdateSchema } from './models/ProductUpdateSchema';
export type { ProductVersion } from './models/ProductVersion';
export type { ProductVersionInputSchema } from './models/ProductVersionInputSchema';
export type { ProductVersionOutputSchema } from './models/ProductVersionOutputSchema';
export { Role } from './models/Role';
export type { RoomHeatersEnabledStateInputSchema } from './models/RoomHeatersEnabledStateInputSchema';
export type { RoomInputSchema } from './models/RoomInputSchema';
export type { RoomOutputSchema } from './models/RoomOutputSchema';
export type { RoomTemperatureInputSchema } from './models/RoomTemperatureInputSchema';
export type { SchedulerDaySchema } from './models/SchedulerDaySchema';
export type { SchedulerSchema } from './models/SchedulerSchema';
export type { SchedulerTimestampSchema } from './models/SchedulerTimestampSchema';
export type { SchedulerWeekSchema } from './models/SchedulerWeekSchema';
export type { UpdateEmailSchema } from './models/UpdateEmailSchema';
export type { UpdatePasswordSchema } from './models/UpdatePasswordSchema';
export type { UpdateRoleSchema } from './models/UpdateRoleSchema';
export type { UpgradeGroupOutputSchema } from './models/UpgradeGroupOutputSchema';
export type { User } from './models/User';
export type { UserConfigurationSchema } from './models/UserConfigurationSchema';
export type { ValidationError } from './models/ValidationError';
export type { WrfFirmware } from './models/WrfFirmware';
export type { WrfFirmwareOutputSchema } from './models/WrfFirmwareOutputSchema';
