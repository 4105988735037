import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import cx from "clsx";

import { HeaterOutputSchema } from "api/generated";
import { routes } from "consts/routes";
import { resolveUrl } from "utils/urls";

import styles from "./DevicesTable.module.scss";

const HEADERS = [
  "Mac",
  "WRF Firmware Version",
  "Client Firmware Version",
  "Added Date",
  "Last Transfer Time",
  "Product Key",
  "Token",
  "Log",
];

type DevicesTableProps = {
  devices: HeaterOutputSchema[];
};

export const DevicesTable = ({ devices }: DevicesTableProps) => (
  <Table>
    <TableHead>
      <TableRow classes={{ root: cx(styles.head, styles.row) }}>
        {HEADERS.map((label, i) => (
          <TableCell className={styles.header} key={i}>
            {label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
    <TableBody>
      {devices.map(
        (
          {
            id,
            name,
            room_id,
            product_id,
            client_firmware,
            wrf_firmware,
            is_enabled,
            is_locked,
            latest_reading_temperature,
            latest_reading_timestamp,
          },
          i
        ) => (
          <TableRow classes={{ root: styles.row }} key={i}>
            <TableCell className={styles.cell}>-</TableCell>
            <TableCell className={styles.cell}>
              {wrf_firmware?.version_name || "-"}
            </TableCell>
            <TableCell className={styles.cell}>
              {client_firmware?.version_name || "-"}
            </TableCell>
            <TableCell className={styles.cell}>-</TableCell>
            <TableCell className={styles.cell}>
              {latest_reading_timestamp || "-"}
            </TableCell>
            <TableCell className={styles.cell}>{product_id}</TableCell>
            <TableCell className={styles.cell}>-</TableCell>
            <TableCell className={styles.cell}>
              <Link
                component={RouterLink}
                to={resolveUrl(routes.DEVICES.LOGS, { id })}
                classes={{ root: styles.link }}
                underline="none"
              >
                View log
              </Link>
            </TableCell>
          </TableRow>
        )
      )}
    </TableBody>
  </Table>
);
