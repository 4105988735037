// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Topbar_topbar__yBOft {\n  height: 48px;\n  padding-left: 24px;\n  z-index: 1201;\n}\n\n.Topbar_toolbar__ecXsT {\n  min-height: 48px;\n  max-height: 48px;\n  height: 48px;\n}", "",{"version":3,"sources":["webpack://./src/components/Topbar/Topbar.module.scss","webpack://./src/styles/_vars.scss"],"names":[],"mappings":"AAEA;EACE,YCDc;EDEd,kBAAA;EACA,aAAA;AADF;;AAIA;EACE,gBCPc;EDQd,gBCRc;EDSd,YCTc;ADQhB","sourcesContent":["@use \"src/styles/vars\";\n\n.topbar {\n  height: vars.$topbar-height;\n  padding-left: 24px;\n  z-index: 1201;\n}\n\n.toolbar {\n  min-height: vars.$topbar-height;\n  max-height: vars.$topbar-height;\n  height: vars.$topbar-height;\n}\n","$base-shadow: rgba(0, 0, 0, 0.1) 0px 2px 8px 0px;\n\n$topbar-height: 48px;\n$side-menu-width: 256px;\n\n$document-width: 540px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topbar": "Topbar_topbar__yBOft",
	"toolbar": "Topbar_toolbar__ecXsT"
};
export default ___CSS_LOADER_EXPORT___;
