// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EditProductForm_form__iPtC1 {\n  display: flex;\n  flex-direction: column;\n  max-width: 420px;\n  width: 100%;\n  margin-bottom: 24px;\n}\n\n.EditProductForm_input__SuWO6 {\n  margin-bottom: 24px;\n}", "",{"version":3,"sources":["webpack://./src/pages/EditProduct/EditProductForm/EditProductForm.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,WAAA;EACA,mBAAA;AACF;;AAEA;EACE,mBAAA;AACF","sourcesContent":[".form {\n  display: flex;\n  flex-direction: column;\n  max-width: 420px;\n  width: 100%;\n  margin-bottom: 24px;\n}\n\n.input {\n  margin-bottom: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "EditProductForm_form__iPtC1",
	"input": "EditProductForm_input__SuWO6"
};
export default ___CSS_LOADER_EXPORT___;
