import React, { useState } from "react";
import ButtonBase from "@mui/material/ButtonBase";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import cx from "clsx";

import { ConfirmationDialog } from "components/ConfirmationDialog";
import { WrfFirmware } from "api/generated";
import { wrf } from "api/wrf";

import styles from "./VersionsTable.module.scss";

const HEADERS = ["Name", " Options"];

type VersionsTableProps = {
  data: WrfFirmware[];
};

export const VersionsTable = ({ data }: VersionsTableProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [versionToDelete, setVersionToDelete] = useState("");

  const { mutate: deleteWrfFirmware, isLoading: isDeleting } =
    wrf.useDeleteWrfFirmware();

  const handleDialogOpen = (id: string) => {
    setVersionToDelete(id);
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setVersionToDelete("");
    setIsDialogOpen(false);
  };

  const handleDialogConfirm = () => {
    deleteWrfFirmware(versionToDelete);
    handleDialogClose();
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow classes={{ root: cx(styles.head, styles.row) }}>
            {HEADERS.map((label, i) => (
              <TableCell className={styles.header} key={i}>
                {label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(({ version_name, id }, i) => (
            <TableRow classes={{ root: styles.row }} key={i}>
              <TableCell className={styles.cell}>{version_name}</TableCell>
              <TableCell className={styles.cell}>
                <ButtonBase
                  classes={{ root: styles.button }}
                  onClick={() => handleDialogOpen(id)}
                  disableRipple
                  disabled={isDeleting}
                >
                  Delete
                </ButtonBase>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ConfirmationDialog
        title="Delete WRF version"
        message="Note that if this WRF Firmware Version is currently being used in any product version or heater group, it will result in those product versions and/or groups being removed as well. Are you sure you want to delete this WRF Firmware Version?"
        isOpen={isDialogOpen}
        onCancel={handleDialogClose}
        onConfirm={handleDialogConfirm}
      />
    </>
  );
};
