import React from "react";
import { Link, useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

import { routes } from "consts/routes";
import { ReactComponent as AppLogo } from "assets/images/app-icon.svg";

import styles from "./Topbar.module.scss";

export const Topbar = () => {
  const location = useLocation();

  return (
    <AppBar className={styles.topbar}>
      <Toolbar disableGutters classes={{ root: styles.toolbar }}>
        <Link
          to={
            location.pathname === routes.LOGIN
              ? routes.LOGIN
              : routes.PRODUCTS.ROOT
          }
        >
          <AppLogo className={styles.logo} />
        </Link>
      </Toolbar>
    </AppBar>
  );
};
