import { useQuery, useMutation, useQueryClient } from "react-query";

import { toast } from "components/Toast";
import { APIService } from "api/APIService";
import { WrfFirmware, HTTPValidationError } from "api/generated";
import { urls } from "api/urls";
import { resolveUrl } from "utils/urls";
import { ErrorSchema } from "types/ErrorSchema";
import { EmptySchema } from "types/EmptySchema";

export type WrfFirmwareInputSchema = {
  version_name: string;
  file: File;
};

const useGetWrfFirmwares = () => {
  return useQuery<WrfFirmware[]>(urls.WRF.LIST, () =>
    APIService.get(urls.WRF.LIST)
  );
};

const useGetWrfFirmware = (id: string) => {
  return useQuery<WrfFirmware>(
    [urls.WRF.FIRMWARE, { id }],
    () => APIService.get(resolveUrl(urls.WRF.FIRMWARE, { id })),
    {
      refetchOnWindowFocus: false,
      staleTime: 0,
    }
  );
};

const useUploadWrfFirmware = () => {
  const queryClient = useQueryClient();

  return useMutation<WrfFirmware, HTTPValidationError | ErrorSchema, FormData>(
    (data) =>
      APIService.post(urls.WRF.LIST, data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      }),
    {
      onSuccess: () => {
        toast();
        queryClient.invalidateQueries(urls.WRF.LIST);
      },
    }
  );
};

const useDeleteWrfFirmware = () => {
  const queryClient = useQueryClient();

  return useMutation<EmptySchema, HTTPValidationError | ErrorSchema, string>(
    (id) => APIService.delete(resolveUrl(urls.WRF.FIRMWARE, { id })),
    {
      onSuccess: (_, id) => {
        toast();
        queryClient.setQueryData<WrfFirmware[]>(
          urls.WRF.LIST,
          (currentData = []) => currentData.filter((item) => item.id !== id)
        );
      },
      onError: () => {
        toast("error");
      },
    }
  );
};

const parseWrfFormForApi = ({ version_name, file }: WrfFirmwareInputSchema) => {
  const formData = new FormData();
  formData.append("version_name", version_name);
  formData.append("file", file);

  return formData;
};

export const wrf = {
  useGetWrfFirmwares,
  useGetWrfFirmware,
  useUploadWrfFirmware,
  useDeleteWrfFirmware,
  parseWrfFormForApi,
};
