import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

import { ProductsTable } from "pages/Products/ProductsTable";
import { useTitle } from "hooks/useAppTheme";
import { products } from "api/products";

import styles from "./Products.module.scss";

export const Products = () => {
  useTitle("Products");

  const { data, isLoading } = products.useGetProducts();

  return (
    <>
      {isLoading ? (
        <CircularProgress className={styles.loading} />
      ) : (
        data && <ProductsTable data={data} />
      )}
    </>
  );
};
