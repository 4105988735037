// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SideMenu_root__CgmZ9,\n.SideMenu_paper__ABSRL {\n  width: 256px;\n}\n\n.SideMenu_list__hd9ER {\n  padding-top: 0;\n}\n\n.SideMenu_paper__ABSRL {\n  margin-top: 48px;\n}\n\n.SideMenu_listItem__ykMUM {\n  background: inherit;\n}\n\n.SideMenu_link__QX7XT {\n  text-decoration: none;\n  color: #000;\n}\n\n.SideMenu_activeLink__BUnM2 {\n  background: #dcdcdc;\n}", "",{"version":3,"sources":["webpack://./src/components/SideMenu/SideMenu.module.scss","webpack://./src/styles/_vars.scss","webpack://./src/styles/colors/_index.scss"],"names":[],"mappings":"AAIA;;EAEE,YCHgB;ADAlB;;AAMA;EACE,cAAA;AAHF;;AAMA;EACE,gBCZc;ADShB;;AAMA;EACE,mBAAA;AAHF;;AAMA;EACE,qBAAA;EACA,WErBM;AFkBR;;AAMA;EACE,mBAAA;AAHF","sourcesContent":["@use \"src/styles/vars\";\n@use \"src/styles/font/family\" as f;\n@use \"src/styles/colors\";\n\n.root,\n.paper {\n  width: vars.$side-menu-width;\n}\n\n.list {\n  padding-top: 0;\n}\n\n.paper {\n  margin-top: vars.$topbar-height;\n}\n\n.listItem {\n  background: inherit;\n}\n\n.link {\n  text-decoration: none;\n  color: colors.$black;\n}\n\n.activeLink {\n  background: #dcdcdc;\n}\n","$base-shadow: rgba(0, 0, 0, 0.1) 0px 2px 8px 0px;\n\n$topbar-height: 48px;\n$side-menu-width: 256px;\n\n$document-width: 540px;\n","$white: #fff;\n$hint: #666666;\n$black: #000;\n\n$primary-mui: #1976d2;\n\n$error: #da1e28;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "SideMenu_root__CgmZ9",
	"paper": "SideMenu_paper__ABSRL",
	"list": "SideMenu_list__hd9ER",
	"listItem": "SideMenu_listItem__ykMUM",
	"link": "SideMenu_link__QX7XT",
	"activeLink": "SideMenu_activeLink__BUnM2"
};
export default ___CSS_LOADER_EXPORT___;
