import { useQuery, useMutation, useQueryClient } from "react-query";

import { toast } from "components/Toast";
import { APIService } from "api/APIService";
import { HTTPValidationError, UpgradeGroupOutputSchema } from "api/generated";
import { urls } from "api/urls";
import { DEFAULT_STALE_TIME } from "consts/api";
import { resolveUrl } from "utils/urls";
import { ErrorSchema } from "types/ErrorSchema";

const useGetUpgradeGroups = () => {
  return useQuery<UpgradeGroupOutputSchema[]>(
    urls.UPGRADE_GROUPS.LIST,
    () => APIService.get(urls.UPGRADE_GROUPS.LIST),
    {
      staleTime: DEFAULT_STALE_TIME,
    }
  );
};

const useGetUpgradeGroup = (id: string) => {
  return useQuery<UpgradeGroupOutputSchema>(
    [urls.UPGRADE_GROUPS.GROUP, { id }],
    () => APIService.get(resolveUrl(urls.UPGRADE_GROUPS.GROUP, { id })),
    {
      refetchOnWindowFocus: false,
    }
  );
};

const useCreateUpgradeGroup = () => {
  const queryClient = useQueryClient();

  return useMutation<
    UpgradeGroupOutputSchema,
    HTTPValidationError | ErrorSchema,
    FormData
  >((data) => APIService.post(urls.UPGRADE_GROUPS.LIST, data), {
    onSuccess: () => {
      toast();
      queryClient.invalidateQueries(urls.UPGRADE_GROUPS.LIST);
    },
  });
};

export const upgradeGroups = {
  useGetUpgradeGroups,
  useGetUpgradeGroup,
  useCreateUpgradeGroup,
};
