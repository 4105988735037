import React, { useState } from "react";
import {
  ButtonBase,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import cx from "clsx";

import { ConfirmationDialog } from "components/ConfirmationDialog";
import { formatDocumentType } from "utils/documents";
import { DocumentOutputSchema } from "api/generated";

import styles from "./DocumentsTable.module.scss";
import { applications } from "api/applications";

const HEADERS = ["Document Type", "Language", "Name", "Options"];

type DocumentsTableProps = {
  data: DocumentOutputSchema[];
};

export const DocumentsTable = ({ data }: DocumentsTableProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState("");

  const { mutate: deleteDocument } = applications.useDeleteDocument();

  const handleDialogOpen = (id: string) => {
    setDocumentToDelete(id);
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDocumentToDelete("");
    setIsDialogOpen(false);
  };

  const handleDialogConfirm = () => {
    deleteDocument(documentToDelete);
    handleDialogClose();
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow classes={{ root: cx(styles.head, styles.row) }}>
            {HEADERS.map((label) => (
              <TableCell className={styles.header} key={label}>
                {label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(({ type, lang, name, url, id }) => (
            <TableRow classes={{ root: styles.row }} key={id}>
              <TableCell className={styles.cell}>
                {formatDocumentType(type)}
              </TableCell>
              <TableCell className={styles.cell}>{lang}</TableCell>
              <TableCell className={styles.cell}>{name}</TableCell>
              <TableCell className={styles.cell}>
                <Link
                  target="_blank"
                  rel="noreferrer"
                  href={url}
                  download
                  className={styles.link}
                  underline="none"
                >
                  Download
                </Link>
                <ButtonBase
                  classes={{ root: styles.button }}
                  onClick={() => handleDialogOpen(id)}
                  disableRipple
                >
                  Delete
                </ButtonBase>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ConfirmationDialog
        title="Delete document"
        message="Are you sure you want to delete this document?"
        isOpen={isDialogOpen}
        onCancel={handleDialogClose}
        onConfirm={handleDialogConfirm}
      />
    </>
  );
};
