/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum DocumentLang {
    EN_US = 'en_us',
    NB_NO = 'nb_no',
}