// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Products_link__hgOLt {\n  margin-bottom: 32px;\n  margin-left: auto;\n  text-decoration: none;\n}\n\n.Products_loading__cxy-E {\n  align-self: center;\n}", "",{"version":3,"sources":["webpack://./src/pages/Products/Products.module.scss"],"names":[],"mappings":"AAGA;EACE,mBAAA;EACA,iBAAA;EACA,qBAAA;AAFF;;AAKA;EACE,kBAAA;AAFF","sourcesContent":["@use \"src/styles/font/family\" as f;\n@use \"src/styles/colors\";\n\n.link {\n  margin-bottom: 32px;\n  margin-left: auto;\n  text-decoration: none;\n}\n\n.loading {\n  align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "Products_link__hgOLt",
	"loading": "Products_loading__cxy-E"
};
export default ___CSS_LOADER_EXPORT___;
