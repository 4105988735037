import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

interface AppTitleContextType {
  title: string | null;
  setTitle: Dispatch<SetStateAction<string | null>>;
}

interface AppTitleProviderProps {
  children?: React.ReactNode;
}

const AppTitleContext = React.createContext<AppTitleContextType | null>(null);

export const useAppTheme = () => {
  const context = useContext(AppTitleContext);
  if (!context) {
    throw new Error("Missing AppTitleProvider");
  }

  const { title } = context;

  return { title };
};

export const useTitle = (title: string | null = null) => {
  const context = useContext(AppTitleContext);
  if (!context) {
    throw new Error("Missing AppTitleProvider");
  }

  useEffect(() => {
    context.setTitle(title);

    return () => {
      context.setTitle(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);
};

export const AppTitleProvider = ({ children }: AppTitleProviderProps) => {
  const [title, setTitle] = useState<string | null>(null);

  const values = useMemo(
    () => ({
      title,
      setTitle,
    }),
    [title]
  );

  return (
    <AppTitleContext.Provider value={values}>
      {children}
    </AppTitleContext.Provider>
  );
};
