import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import ButtonBase from "@mui/material/ButtonBase";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import cx from "clsx";

import { ConfirmationDialog } from "components/ConfirmationDialog";
import { routes } from "consts/routes";
import { User } from "api/generated";
import { users } from "api/users";

import styles from "./UsersTable.module.scss";
import { resolveUrl } from "utils/urls";

const HEADERS = ["E-mail", "Role", " Options"];

type UsersTableProps = {
  data: User[];
};

export const UsersTable = ({ data }: UsersTableProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState("");

  const { mutate: deleteUser } = users.useDeleteUser();

  const handleDialogOpen = (id: string) => {
    setUserToDelete(id);
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setUserToDelete("");
    setIsDialogOpen(false);
  };

  const handleDialogConfirm = () => {
    deleteUser(userToDelete);
    handleDialogClose();
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow classes={{ root: cx(styles.head, styles.row) }}>
            {HEADERS.map((label, i) => (
              <TableCell className={styles.header} key={i}>
                {label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(({ email, role, id }, i) => (
            <TableRow classes={{ root: styles.row }} key={i}>
              <TableCell className={styles.cell}>{email}</TableCell>
              <TableCell className={styles.cell}>
                {role === 1 ? "Admin" : "App User"}
              </TableCell>
              <TableCell className={styles.cell}>
                <Link
                  component={RouterLink}
                  to={resolveUrl(routes.USERS.EDIT, { id })}
                  classes={{ root: styles.link }}
                  underline="none"
                >
                  Edit
                </Link>
                <ButtonBase
                  classes={{ root: styles.button }}
                  onClick={() => handleDialogOpen(id!)}
                  disableRipple
                >
                  Delete
                </ButtonBase>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ConfirmationDialog
        title="Delete user"
        message="Are you sure you want to delete this user?"
        isOpen={isDialogOpen}
        onCancel={handleDialogClose}
        onConfirm={handleDialogConfirm}
      />
    </>
  );
};
