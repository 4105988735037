/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ApiRole {
    APP_USER = 'APP_USER',
    ADMIN = 'ADMIN',
}